import React, { useState } from "react";
import "./Login.css";
import {
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Div from "@jumbo/shared/Div";
import { BASE_URL, Tenantname } from "../../../services/auth-services";
import { useNavigate } from "react-router-dom";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CRMlogo from "../../../assets/images/crmlogo.png";
import {
  FontStyle,
  generateRandomColor,
} from "app/pages/widgets/CRMStyles";
import { useEffect } from "react";
import ForgotPassword from "../forgot-password/ForgotPassword";
import Cookies from "js-cookie";

export let uniqueID = generateRandomColor().split("#")[1];

const Login = () => {
  // eslint-disable-next-line no-unused-vars
  const { setAuthToken } = useJumboAuth();
  const navigate = useNavigate();

  const Item = styled(Paper)(({ theme }) => ({
    ...theme.typography.body2,
    padding: theme.spacing(1),
    color: theme?.palette?.text?.secondary,
  }));

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [Otp, setOtp] = useState("");
  const [OTPNumber, setOTPNumber] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOtpbox, setShowOtpbox] = useState(false);
  const [showLoginbutton, setshowLoginbutton] = useState(true);
  const [validData, setValidData] = useState([]);
  const [role, setRole] = useState("");
  const [progress, setProgress] = useState(300);
  const [progressInterval, setProgressInterval] = useState(null);
  const [errors, setErrors] = useState({});
  const [status, setStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const startProgressInterval = () => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress - 1;
        if (newProgress === 0) {
          setshowLoginbutton(true);
          setShowOtpbox(false);
          clearInterval(interval);
          stopProgressInterval();
        }
        return newProgress;
      });
    }, 1000);
    setProgressInterval(interval);
  };

  const generateOTP = () => {
    var digits = "0123456789";
    let OTP = "";
    for (let i = 0; i < 6; i++) {
      OTP += digits[Math.floor(Math.random() * 10)];
    }
    return OTP;
  };

  const getdata = generateOTP();

  const stopProgressInterval = () => {
    if (progressInterval) {
      clearInterval(progressInterval);
      setProgressInterval(null);
      setOtp("");
      setOTPNumber("");
    }
  };

  const handleGenerateOpt = () => {
    let errors = {};
    if (
      username === undefined ||
      username === null ||
      username?.trim() === ""
    ) {
      errors.username = "User Name is required";
    }
    if (
      password === undefined ||
      password === null ||
      password?.trim() === ""
    ) {
      errors.password = "Password is required";
    }
    setErrors(errors);

    if (Object?.keys(errors)?.length === 0) {
      var payload = {
        username: username,
        subject: "OTP Verification",
        MailContent: `<div className="card">
      <div className="card-body">
        <h4 className="card-title">Hi,</h4>
      
        <p className="card-text">
        To ensure the security of your account, we require you to verify your identity with a One-Time Password (OTP).
        </p>
      <br/>
      <p>
      Here is your OTP:<span style="font-size: 18px; font-weight: 600;"> ${getdata}</span> 
      </p>

      <br/>
      <p>
      Please enter this OTP on the login page within the next 10 minutes to complete the verification process and gain access to your account.
      </p>
      <br/>
      <p>
      If you did not request this OTP or have any concerns about your account's security, please contact our support team immediately.
      </p>
        <br/>

        <br/>
       
        <div> CRMFarm Team </div>
        <br/>
        <br/>
        <br/>

      </div>
    </div>`,
      };

      console.log("Playload", payload);
      setIsLoading(true);

      axios
        .post(`${BASE_URL}/useradmin/GenerateOTP/`, payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((res) => {
          console.log("res", res.data);
          setShowOtpbox(true);
          setshowLoginbutton(false);
          console.log(getdata);
          setOTPNumber(getdata);
          setOtp("");
          setProgress(300);
          startProgressInterval();
          localStorage.setItem("OTP", getdata);
          setIsLoading(false);
        })
        .catch((error) => {
          console.log("error");
          setIsLoading(false);
        });
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  // const validationSchema = yup.object({
  //   username: yup
  //     .string("Enter username")
  //     .required("Email is required")
  //     // .min(5, "Username is weak")
  //     .max(30, "Username is strong"),
  //   password: yup
  //     .string("Enter your password")
  //     .required("Password is required"),
  // });

  // const {
  //   register,
  //   handleSubmit,
  //   reset,
  //   formState: { errors },
  // } = useForm({
  //   resolver: yupResolver(validationSchema),
  // });

  // update login status
  const updateLoginStatus = async () => {
    try {
      const token = localStorage.getItem("accesstoken");
      await axios.patch(
        `${BASE_URL}/useradmin/UserCRUD/`,
        {
          User_Login_Status: true,
          username: username,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
            db: "crmuseradmin1",
          },
        }
      );
    } catch (error) {
      console.error(error);
    }
  };
  // let token = localStorage.setItem("accesstoken", res.data.token);


  const validateForm = () => {
    const newErrors = {};

    if (username === undefined || username === null || username === "") {
      newErrors.username = "User Name is required.";
    }
    if (password === undefined || password === null || password === "") {
      newErrors.password = "Password is required.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const Check_isactiveTenant = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      await axios.get(`${BASE_URL}/useradmin/UserCRUD_GET/?username=${username}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }).then((res) => {
        console.log("res Login Check:", res);
        if (res?.data?.is_Tenantactive == true) {
          handleResetPassword();
        } else {
          toast.error("Your Subscription is inactive", {
            position: "top-right",
            autoClose: 1000,
          });
          setIsLoading(false);
        }
      }).catch((error) => {
        toast.error("Enter valid username and password", {
          position: "top-right",
          autoClose: 100,
        });
        setIsLoading(false);
      });
    }
  };


  const handleResetPassword = (e) => {
    setIsLoading(true);

    var userpayload = {
      username: username,
      password: password,
    };
    axios.post(`${BASE_URL}/auth_token`, userpayload, {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        setIsLoading(false);
        if (res.status === 200 && username != "demouser" && Tenantname != "deepam") {
          if (rememberMe) {
            Cookies.set("LUserName", username);
            Cookies.set("LPassword", password);
            console.log("username:", username);
            console.log("password:", password);
            console.log("Remember me checked and data stored");
          } else {
            // Cookies.set('token', token);
            console.log("Remember me not checked and not data stored");
          }
          handleGenerateOpt();

        }
        localStorage.setItem("accesstoken", res.data.token);
        localStorage.setItem("selectedItems", JSON.stringify([]));
        localStorage.setItem("selectedItems1", JSON.stringify([]));
        localStorage.setItem("isLoggedIn", "true");
        // localStorage.setItem("UniqueUser", uniqueID);
        // localStorage.setItem('isLoggedIn', 'true');
        if (username) {
          const token = localStorage.getItem("accesstoken");
          axios
            .get(`${BASE_URL}/useradmin/UserCRUD/?username=${username}`, {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
                Accept: "application/json",
              },
            })
            .then((response) => {
              setStatus(response.data);
              localStorage.setItem("UserType", response.data.UserType);
              localStorage.setItem(
                "UserDetails",
                JSON.stringify(response.data)
              );
              localStorage.setItem("Username", response.data?.username);
              localStorage.setItem("UserId", response.data.id);
              localStorage.setItem(
                "OrganizationId",
                response.data.Organization_Id
              );
              localStorage.setItem("Designation", response.data.Designation);
              localStorage.setItem("DBName", "crmuseradmin1");
              setRole(response?.data?.Designation);
              console.log("OO", response?.data?.Designation);

              setValidData(response.data);
              if (response?.data?.username == "demouser" || Tenantname === "deepam") {
                if (rememberMe) {
                  Cookies.set("LUserName", username);
                  Cookies.set("LPassword", password);
                  console.log("username:", username);
                  console.log("password:", password);
                  console.log("Remember me checked and data stored");
                } else {
                  // Cookies.set('token', token);
                  console.log("Remember me not checked and not data stored");
                }
                if (response?.data?.UserType === "UserAdmin") {
                  toast.success("Login successfully", {
                    position: "top-right",
                    autoClose: 100,
                  });
                  navigate(`/user-admin-dashboard`);
                } else {
                  navigate(`/home`);
                  toast.success("Login successfully", {
                    position: "top-right",
                    autoClose: 100,
                  });
                }
              }
            })
            .catch(() => { });
        } else {
          toast.error("Enter valid username and password", {
            position: "top-right",
            autoClose: 100,
          });
          navigate("/");
          setUsername("");
          setPassword("");
          setOTPNumber("");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error("Enter valid username and password", {
          position: "top-right",
          autoClose: 100,
        });
        setIsLoading(false);
      });
  };

  const Validateotp = (e) => {
    e.preventDefault();

    console.log(Otp);
    console.log(OTPNumber);
    if (Otp === OTPNumber) {
      updateLoginStatus();
      toast.success("OTP is Verified");
      toast.success("Login successfully", {
        position: "top-right",
        autoClose: 100,
      });

      console.log("success", status, localStorage.getItem("UserType"));
      if (localStorage.getItem("UserType") === "UserAdmin") {
        navigate(`/user-admin-dashboard`);
      } else {
        navigate(`/home`);
      }
    } else {
      return toast.error("Please enter valid OTP");
    }
  };

  //  Remember Me and Forgot Password Design
  const [rememberMe, setRememberMe] = useState(false);
  const [LoginPageView, setLoginPageView] = useState(true);
  const [ForgotPageView, setForgotPageView] = useState(false);

  const handleCheckboxChange = (event) => {
    setRememberMe(event.target.checked);
    console.log("Remember me:", event.target.checked);
  };

  useEffect(() => {
    const savedUsername = Cookies.get("LUserName");
    const savedPassword = Cookies.get("LPassword");
    if (savedUsername && savedPassword) {
      setUsername(savedUsername);
      setPassword(savedPassword);
      setRememberMe(true);
    }
  }, []);


  return (
    <>
      <div className="login_container" style={{ display: LoginPageView === true ? "block" : "none" }}>
        {/* <Grow in> */}
        <Paper elevation={4}>
          <div id="main-wrapper" className="oxyy-login-register">
            <div className="container-fluid login_child_container px-0">
              <div className="row g-0 min-vh-100">
                <div className="col-md-6">
                  <div className="hero-wrap d-flex align-items-center h-100">
                    <div className="hero-mask"></div>
                    <div className="hero-bg hero-bg-scroll"></div>
                  </div>
                </div>
                <div className="col-md-6 d-flex">
                  <div className="container my-auto py-0">
                    <div className="row g-0">
                      <div className="col-11 col-sm-10 col-lg-9 col-xl-8 mx-auto">
                        <div style={{ textAlign: '-webkit-center' }}>
                          <img src={CRMlogo} width={110}></img>
                        </div>
                        <h3 className="fw-600 mb-3">Log In</h3>
                        {/* <form id="loginForm" onSubmit={handleSubmit(Check_isactiveTenant)} */}
                        <form id="loginForm" onSubmit={(Check_isactiveTenant)}
                          style={{ textAlign: "left", color: "white" }}
                          noValidate
                          autoComplete="off">
                          <div className="mb-3">
                            <label htmlFor="Username" className="form-label">Username</label>
                            <input className="form-control" id="emailAddress" required
                              fullWidth
                              type="text"
                              autoComplete="off"
                              // className={` ${
                              //   errors.username ? "is-invalid" : ""
                              // }`}
                              // {...register("username")}
                              value={username}
                              onChange={(e) => {
                                setUsername(e.target.value);
                              }}
                              placeholder="Enter username"
                            />
                            <div style={{ color: "red" }}>
                              {!username && errors?.username}
                            </div>
                          </div>
                          {/* <div className="mb-3">
                        <label htmlFor="password" className="form-label" id="loginPassword">Password</label>
                        <input className="form-control"
                          fullWidth
                          type={showPassword ? "text" : "password"}
                          // className="input-box"
                          // autoComplete="off"
                          // // className={` ${
                          // //   errors.password ? "is-invalid" : ""
                          // // }`}
                          // {...register("password")}
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          placeholder="Enter Your Password"
                        />
                        <div style={{ color: "red" }}>
                          {!password && errors?.password}
                        </div>
                      </div> */}

                          <div className="mb-3">
                            <label htmlFor="password" className="form-label" id="loginPassword">Password</label>
                            <div className="input-group">
                              <input
                                className="form-control"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => {
                                  setPassword(e.target.value);
                                }}
                                placeholder="Enter Your Password"
                                style={{ paddingRight: "40px" }} // Add some padding to the right to make space for the button
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "50%",
                                  transform: "translateY(-50%)",
                                  border: "none",
                                  background: "none",
                                  cursor: "pointer",
                                  zIndex: 999,
                                }}
                                onClick={handleClickShowPassword}
                              >
                                {/* {showPassword ? "🙈" : "👁️"} */}
                                {showPassword ?
                                  // "🙈" 
                                  (
                                    <VisibilityOff
                                      style={{
                                        fontSize: 18,
                                        color: "#579ddf",
                                        padding: "0px !imoportant",
                                      }}
                                    />
                                  )
                                  :
                                  //  "👁️"
                                  (
                                    <Visibility
                                      style={{
                                        fontSize: 18,
                                        color: "#579ddf",
                                        padding: "0px !imoportant",
                                      }}
                                    />
                                  )
                                }
                              </div>
                            </div>
                            <div style={{ color: "red" }}>
                              {!password && errors?.password}
                            </div>
                          </div>
                          <Div
                            sx={{
                              display: showOtpbox === true ? "block" : "none",
                              justifyContent: "center",
                              mt: 3,
                            }}
                          >
                            <Typography
                              sx={[FontStyle, { mt: 2, color: "#102C57" }]}
                            >
                              OTP will expire within <span style={{ color: "#378ddc" }}>{progress}</span> sec
                            </Typography>
                            <Div className="mt-1">
                              <input className="form-control" id="emailAddress" required fullWidth
                                type="text"
                                autoComplete="off"
                                // className={` ${
                                //   errors.password ? "is-invalid" : ""
                                // }`}
                                value={Otp}
                                onChange={(e) => {
                                  setOtp(e.target.value);
                                }} placeholder="Enter OTP" />
                            </Div>
                          </Div>
                          <div className="row d-flex align-items-center mt-2">
                            <div className="col">
                              <div className="form-check d-flex align-items-center">
                                <input
                                  id="remember-me"
                                  name="remember"
                                  className="form-check-input cursor-pointer me-1"
                                  type="checkbox"
                                  checked={rememberMe}
                                  onChange={handleCheckboxChange}
                                />
                                <label
                                  className="form-check-label cursor-pointer"
                                  htmlFor="remember-me"
                                  style={{ color: "#378ddc", }}
                                >
                                  Remember Me
                                </label>
                              </div>
                            </div>
                            <div className="col text-end">
                              <div
                                onClick={() => {
                                  setLoginPageView(false);
                                  setForgotPageView(true);
                                }}
                                className="forgot-password-link"
                              >
                                Forgot Password?
                              </div>
                            </div>
                          </div>

                          <div className="d-grid my-0">
                            {/* <button className="btn btn-primary" type="submit">Login</button> */}
                            <Div
                              sx={{
                                display: showLoginbutton === true ? "flex" : "none",
                                justifyContent: "center",
                                mt: "4rem",
                                my: "1.5rem",
                              }}
                            >
                              <Button
                                type="submit"
                                sx={{
                                  background: "rgb(31 109 175)",
                                  width: "100%",
                                  padding: '10px',
                                  ":hover": {
                                    background: "rgb(71 137 193)",
                                  },
                                  borderRadius: "5px !important",
                                }}
                              >

                                <Typography
                                  sx={{
                                    fontSize: "15px !important",
                                    color: "white !important",
                                  }}
                                >
                                  {isLoading ? (
                                    <div className="d-flex align-middle">
                                      <span>Loading</span>
                                      <CircularProgress size={20} sx={{ marginLeft: '8px', color: 'white' }} />
                                    </div>
                                  ) : ("Login")}
                                </Typography>
                              </Button>
                            </Div>

                            <Div
                              sx={{
                                display: showOtpbox === true ? "block" : "none",
                                justifyContent: "center",
                                mt: "4rem",
                                my: "1.5rem",
                              }}
                            >
                              <Div
                                sx={{ display: "flex", justifyContent: "center" }}
                              >
                                <Button
                                  type="submit"
                                  onClick={Validateotp}
                                  sx={{
                                    background: "rgb(31 109 175)",
                                    width: "100%",
                                    padding: '10px',
                                    ":hover": {
                                      background: "rgb(71 137 193)",
                                    },
                                    borderRadius: "5px !important",
                                  }}
                                >
                                  <Typography
                                    sx={[
                                      {
                                        fontSize: "15px !important",
                                        color: "white !important",
                                      },
                                    ]}
                                  >
                                    {isLoading ? "Loading" : "Submit"}
                                  </Typography>
                                </Button>
                              </Div></Div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Paper>
        {/* </Grow> */}
      </div>

      <div style={{ display: ForgotPageView === true ? "block" : "none" }}>
        <ForgotPassword
          setLoginPageView={setLoginPageView}
          setForgotPageView={setForgotPageView}
          username={username}
        />
      </div>
    </>
  );
};
export default Login;