import React from 'react';
import Div from "@jumbo/shared/Div";
import useJumboContentLayout from "@jumbo/hooks/useJumboContentLayout";

const JumboContentLayoutMain = ({ children }) => {
    const contentLayout = useJumboContentLayout();
    const location = window.location.pathname
    // console.log("locationlocation", location)
    
    return (
        <Div className='JumboCmtLayout-content'
            sx={{
                display: "flex",
                flex: 1,
                mt: 0,
                mb: (window.innerHeight + 5) - window.innerHeight,
                minWidth: 0,
                flexDirection: "column",
                minHeight: "100%",
                // backgroundColor: (location === '/app/contacts') || (location === '/leads/qualifiedLeads') ? '#F7F7F7' : location === "/landingpage" ? "linear-gradient(90deg, hsla(276, 91%, 79%, 1) 0%, hsla(254, 74%, 65%, 1) 100%)" : "#FFFFFF",
                background: location === "/landingpage" ? "#fff" : "#FFFFFF",
                ...contentLayout?.main?.sx,
            }}
        >
            {children}
        </Div>
    );
};

export default JumboContentLayoutMain;