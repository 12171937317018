import PropTypes from "prop-types";
import { useState } from "react";
import { NavLink as RouterLink, useLocation, useNavigate } from "react-router-dom";
// @mui
import { Box, Collapse, List, ListItemText, Stack } from "@mui/material";
import { ArrowWrapper } from "@jumbo/components/JumboVerticalNavbar/style";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
//
import { StyledNavItem, StyledNavItemIcon } from "./style";
import Div from "@jumbo/shared/Div";
import { useStateContext } from "app/contexts/ContextProvider";

// ----------------------------------------------------------------------

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding>
        {data?.map((item) => (
          <Stack sx={{ color: item?.color }}>
            <NavItem className="Nav Admin" key={item?.title} item={item} />
          </Stack>
        ))}
      </List>
    </Box>
  );
}

const NavItem = ({ item }) => {
  const { title, path, icon, iconG, color, children } = item;
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { setLandingpath } = useStateContext();
  const type = localStorage.getItem("UserType");

  const handleClick = () => {
    setOpen(!open);
    if (path) {
      navigate("/landingpage");
      setLandingpath(path);
    } else {
      if (type === "User") {
        navigate("/home");
      } else {
        navigate("/user-admin-dashboard");
      }
    }
  };

  const renderBase = (path, title, icon, iconG, color) => (
    <StyledNavItem
      onClick={children && handleClick}
      disableTypography={location?.pathname == "/" + path ? false : true}
      sx={{
        bgcolor:
          // location?.pathname == "/" + path ? Colors?.Mblue : "white",   // ponkumar changes
          location?.pathname == "/" + path ? "#f5f5f5" : "white",
      }}>
      <StyledNavItemIcon
        sx={{
          color: location?.pathname == "/" + path
            ? "#1b6640"
            : color,
          '& svg': {
            fill: location?.pathname === `/${path}` ? "#1b6640" : color, // Change the SVG color here
          }
        }}>{location?.pathname == "/" + path ? iconG : icon}</StyledNavItemIcon>
      <ListItemText
        sx={{
          fontSize: 12,
          pl: -1,
          color: location?.pathname == "/" + path
            ? "#1b6640"
            : color,
          fontWeight: location?.pathname == "/" + path
            ? 600
            : 500,
        }}
        disableTypography
        primary={title?.charAt(0).toUpperCase() + title?.slice(1) || title}
      />
      {children && (
        <ArrowWrapper>
          {open ? (
            <ArrowDropDownIcon
              sx={{
                mr: 1, fontSize: "20px", color: location?.pathname == "/" + path
                  ? "#1b6640"
                  : color,
              }}
            />
          ) : (
            <ArrowRightIcon
              sx={{
                mr: 1, fontSize: "20px", color: location?.pathname == "/" + path
                  ? "#1b6640"
                  : color,
              }}
            />
          )}
        </ArrowWrapper>
      )}
    </StyledNavItem>
  );

  if (children) {
    return (
      <>
        {renderBase(null, title, icon, iconG, color)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {children.map((subNavItem) => (
              <SubNavItem
                sx={{
                  padding: 0, color: location?.pathname == "/" + path
                    ? "#1b6640"
                    : color,
                }}
                key={subNavItem.title}
                item={subNavItem}
              />
            ))}
          </List>
        </Collapse>
      </>
    );
  }
  
  return (
    <Div
      sx={{
        bgcolor:
          // location?.pathname == "/" + path ? Colors?.Mblue : "white",   // ponkumar changes
          location?.pathname == "/" + path ? "#f5f5f5" : "white",
      }}
    >
      <StyledNavItem component={RouterLink} to={path}>
        <StyledNavItemIcon
          sx={{
            color: location?.pathname == "/" + path
              ? "#1b6640"
              : color,
            '& svg': {
              fill: location?.pathname === `/${path}` ? "#1b6640" : color, // Change the SVG color here
            }
          }}>{location?.pathname == "/" + path ? iconG : icon}</StyledNavItemIcon>
        <ListItemText
          sx={{
            fontSize: 12,
            pl: -1,
            color:
              location?.pathname == "/" + path
                ? "#1b6640"
                : color,
            fontWeight: location?.pathname == "/" + path
              ? 600
              : 500,
          }}
          disableTypography
          primary={title?.charAt(0)?.toUpperCase() + title?.slice(1) || title}
        />
      </StyledNavItem>
    </Div>
  );
};

const SubNavItem = ({ item }) => {
  const { title, path, icon, iconG, color, children } = item;
  console.log("innerSubNav:", children);
  const location = useLocation();
  const type = localStorage.getItem("UserType");

  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { setLandingpath } = useStateContext();

  const handleClick = () => {
    setOpen(!open);
    console.log("pathth", path)
    if (path) {
      navigate("/landingpage");
      setLandingpath(path);
    } else {
      if (type === "User") {
        navigate("/home");
      } else {
        navigate("/user-admin-dashboard");
      }
    }

  };

  const InnerRenderBase = (path, title, icon, iconG, color) => (
    <StyledNavItem onClick={children && handleClick}
      sx={{
        bgcolor:
          // location?.pathname == "/" + path ? Colors?.Mblue : "white",   // ponkumar changes
          location?.pathname == "/" + path ? "#f5f5f5" : "white",
      }}>
      <StyledNavItemIcon
        sx={{
          pl: 2,
          color: location?.pathname == "/" + path
            ? "#1b6640"
            : color,
          '& svg': {
            fill: location?.pathname === `/${path}` ? "#1b6640" : color, // Change the SVG color here
          }
        }}>{location?.pathname == "/" + path ? iconG : icon}</StyledNavItemIcon>
      <ListItemText
        sx={{
          fontSize: 12,
          pl: -1,
          color: location?.pathname == "/" + path
            ? "#1b6640"
            : color,
          fontWeight: location?.pathname == "/" + path
            ? 600
            : 500,
        }}
        disableTypography
        primary={title}
      />
      {children && (
        <ArrowWrapper>
          {open ? (
            <ArrowDropDownIcon
              sx={{ mr: 1, fontSize: "20px", color: color }}
            />
          ) : (
            <ArrowRightIcon
              sx={{ mr: 1, fontSize: "20px", color: color }}
            />
          )}
        </ArrowWrapper>
      )}
    </StyledNavItem>
  );

  if (children) {
    return (
      <>
        {InnerRenderBase(null, title, icon)}
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List
            className="menuPadding"
            sx={{
              padding: "0px",
              // color: color,
              margin: "0px",
              marginLeft: "18px",
            }}
          >
            {children?.map((innerSubNavItem) => (
              <SubNavItem key={innerSubNavItem.title} item={innerSubNavItem} />
            ))}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Div
      sx={{
        bgcolor:
          // location?.pathname == "/" + path ? Colors?.Mblue : "white",   // ponkumar changes
          location?.pathname == "/" + path ? "#f5f5f5" : "white",
      }}
    >
      <StyledNavItem component={RouterLink} to={path}>
        <StyledNavItemIcon
          sx={{
            pl: 2,
            color: location?.pathname == "/" + path
              ? "#1b6640"
              : color,
            '& svg': {
              fill: location?.pathname === `/${path}` ? "#1b6640" : color, // Change the SVG color here
            }
          }}
        >
          {location?.pathname == "/" + path ? iconG : icon}
        </StyledNavItemIcon>
        <ListItemText
          disableTypography
          sx={{
            fontSize: 12,
            pl: -1,
            color: location?.pathname == "/" + path
              ? "#1b6640"
              : color,
            fontWeight: location?.pathname == "/" + path
              ? 600
              : 500,
          }}
          primary={title}
        />
      </StyledNavItem>
    </Div >
  );
};

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
};

SubNavItem.propTypes = {
  item: PropTypes.object,
};
