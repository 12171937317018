import React, { useState, useEffect } from "react";
import "./Header.css";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import AuthUserDropdown from "../../../../shared/widgets/AuthUserDropdown";
import NotificationsDropdown from "../../../../shared/NotificationsDropdown";
import MessagesDropdown from "../../../../shared/MessagesDropdown";
import {
  Grid,
  IconButton,
  ListItemIcon,
  Slide,
  Typography,
  useMediaQuery,
} from "@mui/material";
import Div from "@jumbo/shared/Div";
import CloseIcon from "@mui/icons-material/Close";
import JumboIconButton from "@jumbo/components/JumboIconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Logo from "../../../../shared/Logo";
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from "@jumbo/utils/constants";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { DrawerHeader } from "@jumbo/components/JumboLayout/style";
import { Link, useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import Crmpackage from "app/pages/HeaderMailicon/Crmpackage";
import SettingsHeaderDropdown from "app/shared/widgets/ASettingsHeader/SettingsHeaderDropdown";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL, socket, Tenantname } from "app/services/auth-services";
import axios from "axios";
import {
  GmailSecertKey,
  getGeoLocationKey,
} from "app/redux/reducers/locationApp";
import { SiSimpleanalytics } from "react-icons/si";
import { useStateContext } from "app/contexts/ContextProvider";
import CustomNotification from "app/pages/leads/CustomNotification";
import { notification } from "antd";
import { Cancelbutton, CustomButton } from "app/pages/widgets/StylesComponents";
import { selectUserRolePermissions } from "app/redux/actions/fetchUserRolePermissions";


const Header = () => {
  const token = localStorage.getItem("accesstoken");
  const [type, setType] = useState("User");
  const [type1, setType1] = useState("UserAdmin");
  const [foe, setFoe] = useState(`${localStorage.getItem("UserType")}`);
  const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar();
  const [dropdownSearchVisibility, setDropdownSearchVisibility] = useState(false);

  const { headerTheme } = useJumboHeaderTheme();

  const navigate = useNavigate();

  const showDropdownSearch = useMediaQuery("(max-width:575px)");

  const showHeaderMenu = useMediaQuery("(min-width:575px)");

  const showSettingIcon = useMediaQuery("(min-width:575px)");

  const HomeMenu = () => {
    setSidebarOptions({ open: true });
  };

  const PurchaseMenu = () => {
    setSidebarOptions({ open: true });
  };

  const SettingMenu = () => {
    setSidebarOptions({ open: true });
  };

  console.log("type", `${type}`);
  console.log("foe", foe);
  console.log(`${localStorage.getItem("UserType")}`);
  const item = "Admin";
  const check = `${localStorage.getItem("UserType")}`;
  console.log("check", check);
  console.log("item", String(item));

  const dispatch = useDispatch();

  // useEffect(() => {
  //   const getMapData = () => {
  //     axios.get(`${BASE_URL}/user/MapConfigurationCRUD/?User_Id=${localStorage.getItem("UserId")}`)
  //       .then((res) => {
  //         dispatch(setGoogleMapsApiKey(res?.data?.API_Key));
  //         console.log("res?.data?.API_Key",res?.data?.API_Key)
  //       })
  //       .catch((err) => {
  //         console.log("err", err);
  //       });

  //   };

  //   getMapData();
  // }, [dispatch]);

  useEffect(() => {
    dispatch(getGeoLocationKey());
    dispatch(GmailSecertKey());
  }, [dispatch]);

  const NotificatioCallCenterActivityModule = async () => {
    await axios
      .get(
        `${BASE_URL}/user/NotificatioCallCenterActivityModule/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        DealNotificationStage();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const DealNotificationStage = async () => {
    await axios
      .get(
        `${BASE_URL}/user/DealNotificationStage/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        NotificationDealUpdatedStages();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const NotificationDealUpdatedStages = async () => {
    await axios
      .get(
        `${BASE_URL}/user/NotificationDealUpdatedStages/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        MeetingNotificationStage();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const MeetingNotificationStage = async () => {
    await axios
      .get(
        `${BASE_URL}/user/MeetingNotificationStage/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        InActiveLeads();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const InActiveLeads = async () => {
    await axios
      .get(
        `${BASE_URL}/user/InActiveLeads/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
        InActiveAccounts();
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const InActiveAccounts = async () => {
    await axios
      .get(
        `${BASE_URL}/user/InActiveAccounts/?UserId=${localStorage.getItem(
          "UserId"
        )}&Organization_Id=${localStorage.getItem("OrganizationId")}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      )
      .then((res) => {
        console.log("res", res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    const setDailyTimeout = () => {
      const now = new Date();
      const nextSixPM = new Date();

      nextSixPM.setHours(0, 14, 0, 0); // Set the time to 6 PM

      // If it's already past 6 PM, set the date to the next day

      if (now.getTime() > nextSixPM.getTime()) {
        nextSixPM.setDate(nextSixPM.getDate() + 1);
      }

      const timeUntilNextSixPM = nextSixPM.getTime() - now.getTime();

      setTimeout(() => {
        performTask(); // Function to perform your task
        setDailyTimeout(); // Set the next timeout
      }, timeUntilNextSixPM);
    };

    const performTask = () => {
      console.log("Trigger");
      NotificatioCallCenterActivityModule();
    };

    setDailyTimeout();
  }, []);

  //  Scoket List Functionality
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const {
    initialState,
    reminder,
    setReminder,
    count,
    setCount,
    setCountnumber,
    countnumber,
  } = useStateContext();
  const [remain, setRemain] = useState([]);


  const [expanded, setExpanded] = useState(false);
  const [scheduledMessage, setScheduledMessage] = useState(null);
  // zing
  const [callingURI, setCallingURI] = useState("");
  const [video, setVideo] = useState(false);


  const generateURL = () => {
    let ran = "";
    const characters = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    for (let i = 0; i < 50; i++) {
      ran += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    let meetingUrl = "";
    meetingUrl = `https://jitsi.crmfarm.app/${ran}`;
    setCallingURI(meetingUrl);
    setVideo(true);
    // setSidebarOptions({ open: false })
  };



  const NotificationSound = () => {
    const notificationSound = new Audio(
      require("../../../../assets/audio/Bell.mp3")
    );
    // Play notification sound (if allowed by browser)
    if (notificationSound) {
      try {
        const playPromise = notificationSound.play();
        if (playPromise !== undefined) {
          playPromise.catch((error) => {
            console.error("Failed to play audio:", error);
          });
        }
      } catch (error) {
        console.error("Failed to play audio:", error);
      }
    }
  };


  // for socket api 
  const handleSocketMessage = (data) => {
    console.log("Scheduled message received:", data);
    setScheduledMessage(data);
    RemainderData();
    setCountnumber((prevCountnumber) => {
      const updatedCountnumber = [...prevCountnumber, 1];
      const sum = updatedCountnumber.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      console.log("updatedCountnumber", updatedCountnumber);
      setCount(sum);
      return updatedCountnumber;
    });
    NotificationSound();
  };

  // List of event names to listen to
  const socketEventNames = [
    "ContactMeetingMessage",
    "AccountMeetingMessage",
    "DealMeetingMessage",
    "LeadMeetingMessage",
    "ContactActivityMessage",
    "AccountActivityMessage",
    "DealActivityMessage",
    "LeadActivityMessage",
    "scheduledMessage",
    "SalesMessage",
    "CallCenterMessage",
    // "WebScrapingResponse"
  ];

  const closeNotification = () => {
    setExpanded(false);
  };

  // Define RemainderData function
  const RemainderData = (data) => {
    setExpanded(true);
    setTimeout(() => {
      setExpanded(false);
    }, 30000);
  };

  // Define notification sound
  const notificationSound = new Audio(
    require("../../../../assets/audio/Bell.mp3")
  );

  const openNotification = (data, placement) => {
    notification.info({
      message: `${data?.Meassage}`,
      description: (
        <>
          <Typography className="mt-3">
            Do you want to Allow or Deny?
          </Typography>
          <div className="flex items-center gap-3 mt-3">
            <CustomButton
              onClick={() => {
                socket.emit("ChatWarningMsg", {
                  ...data,
                  message: "Allowed",
                });
                notification.destroy();
              }}
              label="Allow"
              Icon=""
            />
            <Cancelbutton
              onClick={() => {
                socket.emit("ChatWarningMsg", {
                  ...data,
                  message: "Blocked",
                });
                notification.destroy();
              }}
              label={`Deny`}
              Icon=""
            />
          </div>
        </>
      ),
      placement,
    });
  };

  useEffect(() => {
    const addSocketListeners = () => {
      // Clean up any previous listeners before adding new ones
      socketEventNames.forEach((eventName) => {
        socket.off(eventName); // Remove any previous listener
        socket.on(eventName, handleSocketMessage); // Add new listener
      });
    };

    // Listen for receive_notification_message event
    socket.on("receive_notification_message", (data) => {
      if (
        Number(localStorage.getItem("UserId")) === Number(data?.Receiver_Id)
      ) {
        console.log(
          "response receive_notification_message : ",
          data?.pop()?.Meassage
        );
        openNotification(data?.pop(), "topRight");
        notificationSound.play().catch((error) => {
          console.error("Failed to play audio:", error);
        });
      }
    });
    // Initialize socket listeners
    addSocketListeners();
    // Clean up event listeners on component unmount
    return () => {
      socketEventNames.forEach((eventName) => {
        socket.off(eventName); // Remove listener when component unmounts
      });
      socket.off("receive_notification_message");
      socket.off("ChatWarningMsg");
    };
  }, []); // Empty dependency array ensures this effect runs once on mount

  const getSocket = () => {
    if (!socket) {
      console.error("Socket is not initialized.");
      return;
    }
    socket.on("connect", () => {
      console.log("Connected to the server with Socket ID:", socket.id);
    });
    socket.on("disconnect", () => {
      console.log("Disconnected from the server with Socket ID:", socket.id);
    });
    if (socket) {
      const emitEvent = (eventName) => {
        socket.emit(eventName, {
          RemainderList: {
            UserId: parseInt(localStorage.getItem("UserId")),
            db: Tenantname,
            Updated_By: parseInt(localStorage.getItem("UserId")),
            Created_By: parseInt(localStorage.getItem("UserId")),
            startdate: new Date().toISOString().split('T')[0],
            enddate: new Date().toISOString().split('T')[0]
          },
        });
      };

      const events = [
        "remainder_List",
        "CallCenterActivity",
        "SalesSchdule",
        "LeadActivitySchdule",
        "DealActivitySchdule",
        "AccountActivitySchdule",
        "ContactActivitySchdule",
        "LeadMeetingSchdule",
        "DealMeetingSchdule",
        "AccountMeetingSchdule",
        "ContactMeetingSchdule",
        "WebscrapingAPI"
      ];
      events.forEach((event) => emitEvent(event));
    }
  };
  
  useEffect(() => {
    getSocket();
  }, []); // Runs only once on mount


  const { userRolePermissions, formPermissions, modulePermissions } = useSelector(selectUserRolePermissions);


  return (
    <React.Fragment>
      <Div
        style={{
          position: "absolute",
          height: "100%",
          left: "0px",
          // transform: "translateX(-93%)",
          display: callingURI === "" ? "none" : "",
          zIndex: 99999,
        }}
      >
        <Grid container>
          <Grid item xs={12}>
            {video && (
              <Div sx={{ width: sidebarOptions.open ? "80% !important" : "100%", height: "100vh" }}>
                <IconButton
                  sx={{
                    width: "30px !important",
                    height: "30px !important",
                    top: 10,
                    right: 10,
                    position: "absolute",
                  }}
                  onClick={() => {
                    setVideo(false);
                  }}
                >
                  <CloseIcon color="black" />
                </IconButton>
                {/* <Div sx={{
                   width: "40px !important",
                   height: "40px !important",
                   top: 10,
                   left:100,
                   position: "absolute",

                }}>
                  <img src={require('../../../assets/images/crmlogo.png')}/>
                </Div> */}

                {
                  <iframe
                    title="Meet Video Call"
                    src={callingURI}
                    allow="camera; microphone; fullscreen; display-capture"
                    style={{ width: sidebarOptions.open ? "80vw" : "99vw", height: "100vh" }}
                  />
                }
              </Div>
            )}
          </Grid>
        </Grid>
      </Div>

      {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
        sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
        (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT &&
          !sidebarOptions.open)) && (
          <>
            <DrawerHeader>
              <Div className="flex items-center gap-2 justify-between w-[230px] transform -translate-x-[40px]">
                <Logo />
                <IconButton
                  edge="start"
                  color="inherit"
                  mode={headerTheme.type ?? "light"}
                  aria-label="open drawer"
                  sx={{
                    ml:
                      sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER
                        ? -1
                        : -2,
                    mr: 1,
                    overflow: "hidden",
                    fontSize: "0.5rem",
                    borderRadius: "1px",
                    padding: "6px 26px 18px 18px",
                    height: "70px",
                    backgroundColor: "white",
                    "&:hover,&:active": {
                      backgroundColor: "inherit",
                      color: "#3c7c33 ",
                    },
                  }}
                  onClick={() =>
                    setSidebarOptions({ open: !sidebarOptions.open })
                  }
                >
                  <>
                    {sidebarOptions?.open ? (
                      <div className="open-close-icon-theme2">
                        <MenuOpenIcon />
                      </div>
                    ) : (
                      <div className="open-close-icon-theme2">
                        <MenuIcon />
                      </div>
                    )}
                  </>
                </IconButton>
              </Div>
            </DrawerHeader>
          </>
        )}
      {sidebarOptions?.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER && <></>}
      {showDropdownSearch && (
        <Slide in={dropdownSearchVisibility}>
          <Div
            sx={{
              zIndex: 1,
              left: 0,
              right: 0,
              position: "absolute",
              height: "65px",
            }}
          >
            <IconButton
              sx={{
                position: "absolute",
                right: 0,
                top: "50%",
                color: "inherit",
                transform: "translateY(-50%)",
              }}
              onClick={() => setDropdownSearchVisibility(false)}
            >
              <div className="open-close-icon-theme2">
                <CloseIcon mode={headerTheme.type ?? "light"} />
              </div>
            </IconButton>
          </Div>
        </Slide>
      )}

      {showHeaderMenu && (
        <div className="header-menus">
          <h2
            className="col"
            id="home_"
            style={{
              padding: "0px 20px 0px 15px",
              fontWeight: "400",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <Link to="/user-admin-dashboard" onClick={HomeMenu}></Link>
          </h2>

          <h2
            className="col"
            style={{
              padding: "0px 15px 0px 15px",
              fontWeight: "400",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <Link
              to="/licence/list-licence"
              href="#purchase_"
            // onClick={PurchaseMenu}
            ></Link>
          </h2>

          <h2
            className="col"
            style={{
              padding: "0px 15px 0px 15px",
              fontWeight: "400",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <Link to="/lookup/list-lookup" href="#lookup_"></Link>
          </h2>

          <h2
            className="col"
            style={{
              padding: "0px 15px 0px 15px",
              fontWeight: "400",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            <Link to="/ticket/list-ticket" href="#ticket_"></Link>
          </h2>
        </div>
      )}

      {/* {!showDropdownSearch && (
        <Div sx={{ marginLeft: { xs: "0px", md: "120px" } }}>
          <SearchGlobal
            sx={{
              minWidth: { xs: 0, md: 360 },
              maxWidth: { xs: 240, md: 420 },
            }}
          />
        </Div>
      )} */}

      <Stack
        direction="row"
        alignItems="center"
        spacing={{ xs: 1, md: 1.3 }}
        className="Notification-icon"
        sx={{ ml: { xs: -1, md: "auto" } }}
      >
        <ThemeProvider theme={headerTheme}>
          <JumboIconButton elevation={25}>
            <SiSimpleanalytics
              onClick={() => navigate("/MLAnalytics")}
              sx={{ fontSize: "1.25rem" }}
            />
          </JumboIconButton>
        </ThemeProvider>
        <ThemeProvider theme={headerTheme}>
          <JumboIconButton elevation={25}>
            <ListItemIcon
              onClick={() => {
                generateURL();
              }}
              sx={{ minWidth: 36 }}
              className="flex items-center justify-center"
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-camera-video-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M0 5a2 2 0 0 1 2-2h7.5a2 2 0 0 1 1.983 1.738l3.11-1.382A1 1 0 0 1 16 4.269v7.462a1 1 0 0 1-1.406.913l-3.111-1.382A2 2 0 0 1 9.5 13H2a2 2 0 0 1-2-2z" />
              </svg>
            </ListItemIcon>
          </JumboIconButton>
        </ThemeProvider>
        <MessagesDropdown />
        <NotificationsDropdown />
        {modulePermissions?.includes(20) &&
          <SettingsHeaderDropdown />
        }
        <ThemeProvider theme={headerTheme}>
          <JumboIconButton elevation={25}>
            <ListItemIcon
              onClick={() => {
                navigate("/customer-support/ticket-livecharts");
              }}
              sx={{ minWidth: 36 }}
              className="flex items-center justify-center"
            >
              <i class="bi bi-chat-dots" style={{ color: '#0c5c00' }}></i>
            </ListItemIcon>
          </JumboIconButton>
        </ThemeProvider>
        <Crmpackage />
        <div className="profile-icon">
          <AuthUserDropdown />
        </div>
      </Stack>

      <>
        {expanded && (
          <CustomNotification
            // reminder={reminder}
            // setReminder={setReminder}
            messages={scheduledMessage}
            setScheduledMessage={setScheduledMessage}
            onClose={closeNotification}
            expanded={expanded}
          />
        )}
      </>

      {/* <Sidebar showPartnerMenus={showPartnerMenus}/> */}
    </React.Fragment>
  );
};

export default Header;
