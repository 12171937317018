import { Dialog, DialogActions, DialogContent, Grid, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import Div from "@jumbo/shared/Div";
import { BASE_URL } from "app/services/auth-services";
import { Cancelbutton, CustomButton, } from "app/pages/widgets/StylesComponents";



const TwilioConfiguration = ({ open9, setOpen9 }) => {
    const token = localStorage.getItem("accesstoken");
    const organizationId = localStorage.getItem("OrganizationId");
    const userId = localStorage.getItem("UserId");

    const header = {
        headers: {
            "db": "crmuseradmin",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            Accept: "application/json",
        },
    };

    const [id, setId] = useState("");
    const [accountId, setAccountId] = useState("");
    const [authToken, setAuthToken] = useState("");
    const [mobileNo, setMobileNo] = useState("");
    const [mobileNo1, setMobileNo1] = useState("");


    const cancelFunc = () => {
        setOpen9(false);
    }

    // update mail configuration
    const handleSubmitTwilioConfigure = (e) => {
        e.preventDefault();
        if (!id) {
            var payload = {
                Twilo_Account_Sid: accountId,
                TWilo_Auth_Token: authToken,
                Mobile_Number: mobileNo,
                User_Id: parseInt(localStorage.getItem("UserId")),
                Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
                Created_Date: new Date().toISOString().split("T")[0],
                Created_By: parseInt(localStorage.getItem("UserId")),
                Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            axios
                .post(`${BASE_URL}/user/TwiloConfigurationCRUD/`, payload, header)
                .then((res) => {
                    if (res?.data?.id) {
                        toast.success("Twilio Configured Successfully");
                        setOpen9(false);
                    } else if (mobileNo === mobileNo1) {
                        toast.warning("Twilio is Already Configured");
                    } else if (mobileNo != mobileNo1) {
                        toast.warning("Please Configure Twilio");
                    } else {
                        toast.error("Check Your Mobile Number");
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        const errorMessage = error.response.data.details || "You don't have permission to do it";
                        console.log(errorMessage);
                        toast.error(errorMessage, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    } else {
                        toast.error(`${error}`, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    }
                });
        } else {
            var payload = {
                id: id,
                Twilo_Account_Sid: accountId,
                TWilo_Auth_Token: authToken,
                Mobile_Number: mobileNo,
                Organization_Id: parseInt(localStorage.getItem("OrganizationId")),
                User_Id: parseInt(localStorage.getItem("UserId")),
                Created_By: parseInt(localStorage.getItem("UserId")),
                Updated_By: parseInt(localStorage.getItem("UserId")),
            };
            axios
                .put(`${BASE_URL}/user/TwiloConfigurationCRUD/`, payload, header)
                .then((res) => {
                    if (res?.data?.id) {
                        toast.success("Twilio Configured Updated Successfully");
                        setOpen9(false);
                    } else if (mobileNo === mobileNo1) {
                        toast.warning("Twilio is Already Configured");
                    } else if (mobileNo != mobileNo1) {
                        toast.warning("Please Configure Twilio");
                    } else {
                        toast.error("Check Your Mobile Number");
                    }
                })
                .catch((error) => {
                    if (error.response && error.response.status === 403) {
                        const errorMessage = error.response.data.details || "You don't have permission to do it";
                        console.log(errorMessage);
                        toast.error(errorMessage, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    } else {
                        toast.error(`${error}`, {
                            position: "top-right",
                            autoClose: 100,
                        });
                    }
                });
        }
    };

    useEffect(async () => {
        if (organizationId) {
            await axios
                .get(
                    `${BASE_URL}/user/TwiloConfigurationCRUD/?Organization_Id=${organizationId}`,
                    header
                )
                .then((res) => {
                    if (res?.data) {
                        setId(res?.data?.id);
                        setAccountId(res?.data?.Twilo_Account_Sid);
                        setAuthToken(res?.data?.TWilo_Auth_Token);
                        setMobileNo(res?.data?.Mobile_Number);
                        setMobileNo1(res?.data?.Mobile_Number);
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, []);


    return (
        <>
            <Dialog minWidth={"xs"} open={open9} onClose={() => setOpen9(false)}>
                <form onSubmit={handleSubmitTwilioConfigure}>
                    <Div sx={{ p: 3 }}>
                        <Typography variant="h3">Twilio Configuration</Typography>
                        <DialogContent>
                            <Grid container sx={{ p: 0, m: 0 }}>
                                <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Twilio Account Id  <span className="required">*</span>
                                        </Typography>
                                        <TextField
                                            className="input-box2 col-12 ps-2"
                                            name="accountId"
                                            value={accountId}
                                            onChange={(e) => setAccountId(e.target.value)}
                                            placeholder="Enter Your Twilio Account Id"
                                            sx={{ minWidth: { xs: "100px", md: "350px" }, maxWidth: "500px" }}
                                        />
                                    </Div>
                                </Grid>
                                <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Twilio Auth Token  <span className="required">*</span>
                                        </Typography>
                                        <TextField
                                            className="input-box2 col-12 ps-2"
                                            name="authToken"
                                            value={authToken}
                                            onChange={(e) => setAuthToken(e.target.value)}
                                            placeholder="Enter Your Twilio Auth Token"
                                            sx={{ minWidth: { xs: "100px", md: "350px" }, maxWidth: "500px" }}
                                        />
                                    </Div>
                                </Grid>
                                <Grid item>
                                    <Div className="row">
                                        <Typography className="input-label col-12">
                                            Mobile Number <span className="required">*</span>
                                        </Typography>
                                        <TextField
                                            className="input-box2 col-12 ps-2"
                                            name="mobileNo"
                                            value={mobileNo}
                                            onChange={(e) => setMobileNo(e.target.value)}
                                            placeholder="Enter Your Mobile Number"
                                            sx={{ minWidth: { xs: "100px", md: "360px" }, maxWidth: "500px" }}
                                        />
                                    </Div>
                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
                            {id ? (
                                <CustomButton label="Update" type="submit" Icon="saveIcon" />
                            ) : (
                                <CustomButton label="Save" type="submit" Icon="saveIcon" />
                            )}
                            <Cancelbutton label="Cancel" onClick={() => cancelFunc()} />

                        </DialogActions>
                    </Div>
                </form>
            </Dialog>
        </>
    );
}

export default TwilioConfiguration
