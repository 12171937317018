import { keyframes, styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";
import { purple, grey } from "@mui/material/colors";
import useMediaQuery from "@mui/material/useMediaQuery";
import { memo, useEffect, useState } from "react";
import * as React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputAdornment,
  Pagination,
  Popper,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  alpha,
} from "@mui/material";
import {
  MdArrowDropDown,
  MdCalendarToday,
  MdCall,
  MdDelete,
  MdKeyboardDoubleArrowRight,
  MdSave,
} from "react-icons/md";
import {
  AvatarFont,
  Colors,
  DisplayFlex,
  FontStyle,
  avatarColors,
  colorCode,
  dialogheading,
} from "./CRMStyles";
import { IoMdCheckmark, IoMdClose, IoMdPrint } from "react-icons/io";
import {
  IoCheckmark,
  IoCloudDownloadOutline,
  IoCloudUpload,
  IoCloudUploadOutline,
} from "react-icons/io5";
import { IoMdSend } from "react-icons/io";
import { format } from "date-fns";
import { BsFilterLeft } from "react-icons/bs";
import { useQueryClient } from "react-query";
import { CiWarning } from "react-icons/ci";
import { BiEdit, BiLeftArrowAlt, BiRightArrowAlt } from "react-icons/bi";
import { GrFormView } from "react-icons/gr";
import { MailIcon } from "app/icons/PngIcons";
import JumboScrollbar from "@jumbo/components/JumboScrollbar";
import Paper from "@mui/material/Paper";
import Draggable from "react-draggable";
import axios from "axios";
import { BASE_URL } from "app/services/auth-services";
import ReactQuill from "react-quill";
import { VscPreview } from "react-icons/vsc";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import styledComponents from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDateFormater,
  selectedDateFormat,
} from "app/redux/actions/fetchDateFormater";
import JumboContentLayoutMain from "@jumbo/components/JumboContentLayout/JumboContentLayoutMain";
import { AutoHeight } from "app/redux/auth/localStorage";
import PropTypes from "prop-types"; // For prop validation
import GaugeChart from "react-gauge-chart";
import ReactSpeedometer from "react-d3-speedometer";
import moment from "moment";
import properlyImg from "../../assets/images/property.jpg";
import { toast } from "react-toastify";


export const capitalizeWords = (str) => {
  if (typeof str === "string") {
    return str?.toLowerCase()?.replace(/\b\w/g, (char) => {
      return char.toUpperCase();
    });
  }
  return str;
};

export const Div = styled("div")({});
// ---------------------------------------Vijay------------------------//

export const formatKey = (key = "") => {
  console.log("formatKey");
  return key
    ?.replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char?.toUpperCase());
};

export const formatIndianNumber = (number) => {
  if (isNaN(number)) {
    return 0; // Return a message for invalid input
  }
  const numericValue = parseFloat(number);
  return new Intl.NumberFormat("en-IN", { minimumFractionDigits: 2 }).format(
    numericValue
  );
};

export const CellNoborder = styled(TableCell)(({ theme }) => ({
  border: "none !important",
}));
export const TableNoborder = styled(Table)(({ theme }) => ({
  border: "none !important",
}));
export const TableRowNoborder = styled(TableRow)(({ theme }) => ({
  border: "none !important",
}));

export const formatNumberSuffix = (num) => {
  if (num < 1000) {
    return num.toString();
  } else if (num >= 1000 && num < 100000) {
    return (num / 1000).toFixed(0) + "k";
  } else if (num >= 100000 && num < 10000000) {
    return (num / 100000).toFixed(0) + " lakh";
  } else if (num >= 10000000) {
    return (num / 10000000).toFixed(0) + " crore";
  } else {
    return num.toString();
  }
};

export const formatDateToMonthYear = (dateString) => {
  const date = new Date(dateString);
  return format(date, "yyyy-MMMM"); // Using date-fns to format the date
};

export const isDateString = (str) => {
  return /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}$/.test(str);
};
//-------------------------Vijay----------------------------------------------//

export const CustomIcons = {
  createIcon: <AddIcon style={{ fontSize: 18, fontWeight: 900 }} />,
  deleteIcon: <MdDelete style={{ fontSize: 18, fontWeight: 900 }} />,
  uploadIcon: (
    <IoCloudUploadOutline style={{ fontSize: 18, fontWeight: 900 }} />
  ),
  downloadIcon: (
    <IoCloudDownloadOutline style={{ fontSize: 18, fontWeight: 900 }} />
  ),
  EditIcon: <BiEdit style={{ fontSize: 18, fontWeight: 900 }} />,
  filterIcon: <BsFilterLeft style={{ fontSize: 18, fontWeight: 900 }} />,
  saveIcon: <SaveIcon style={{ fontSize: 18, fontWeight: 900 }} />,
  sendIcon: <IoMdSend style={{ fontSize: 18, fontWeight: 900 }} />,
  checkIcon: <IoCheckmark style={{ fontSize: 18, fontWeight: 900 }} />,
  nextIcon: <BiRightArrowAlt style={{ fontSize: 18, fontWeight: 900 }} />,
  backIcon: <BiLeftArrowAlt style={{ fontSize: 18, fontWeight: 900 }} />,
  moveIcon: (
    <MdKeyboardDoubleArrowRight style={{ fontSize: 18, fontWeight: 900 }} />
  ),
  closeIcon: (
    <IoMdClose style={{ fontSize: { xs: 16, md: 23 }, fontWeight: 900 }} />
  ),
  viewIcon: (
    <GrFormView style={{ fontSize: { xs: 16, md: 23 }, fontWeight: 900 }} />
  ),
  downArrowIcon: (
    <MdArrowDropDown
      style={{ fontSize: { xs: 14, md: 23 }, fontWeight: 900 }}
    />
  ),
  PreviewIcon: <VscPreview style={{ fontSize: 15, fontWeight: 900 }} />,
  callIcon: <MdCall style={{ fontSize: 15, fontWeight: 900 }} />,
  mailIcon: <MailIcon size={13} color={Colors?.Mblue} />,
  printIcon: <IoMdPrint size={13} color={Colors?.Mblue} />,
  tickIcon: <IoMdCheckmark size={13} />,
};

// --------------------------------------- Customization Buttons ---------------------------------
// Styled button with custom styles
export const StyledButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText(purple[500]),
  fontSize: FontStyle?.fontSize,
  boxShadow: "none",
}));

// create button
export const CreateButton = ({
  onClick,
  customStyles = {},
  label = "Create",
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <StyledButton
      sx={[
        matches && customStyles,
        {
          width: matches ? "100px" : "40px",
          height: "33px",
          backgroundColor: Colors?.Mblue,
          "&:hover": {
            backgroundColor: Colors?.MHoverblue,
            boxShadow: "none",
          },
        },
      ]}
      // variant="contained"
      startIcon={
        matches && (
          <AddIcon style={{ fontSize: !matches ? 25 : 18, fontWeight: 900 }} />
        )
      }
      onClick={onClick}
    >
      {matches ? (
        capitalizeWords(label)
      ) : (
        <AddIcon style={{ fontSize: !matches ? 25 : 18, fontWeight: 900 }} />
      )}
    </StyledButton>
  );
};


export const CustomButton = ({
  onClick = null,
  sx = {},
  label = "No Label",
  Icon = "createIcon",
  bgColor = Colors?.Mblue,
  textColor = "white !important",
  type = "button",
  width = null,
  disabled = false,
  endIcon = false,
  isLoading = false,
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <StyledButton
      sx={[
        matches && sx,
        {
          width: Boolean(width) ? width : matches ? "100px" : "60px",
          height: "33px",
          backgroundColor: bgColor,
          "&:hover": {
            backgroundColor: alpha(bgColor, 0.8),
            boxShadow: "none",
          },
          color: textColor,
        },
      ]}
      type={type}
      disabled={disabled}
      startIcon={!endIcon && matches && Boolean(Icon) ? CustomIcons[Icon] : ""}
      endIcon={endIcon && matches && Boolean(Icon) ? CustomIcons[Icon] : ""}
      onClick={onClick}
    >
      {isLoading ? <CircularProgress size={20} color="inherit" /> : capitalizeWords(label)}
    </StyledButton>
  );
};

// cancel button
export const Cancelbutton = ({
  onClick = null,
  sx = {},
  label = "Cancel",
  Icon = "closeIcon",
  width = null,
  textColor = "black",
  disabled = false,
}) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <StyledButton
      sx={[
        matches && sx,
        {
          width: Boolean(width) ? width : matches ? "100px" : "40px",
          height: "33px",
          backgroundColor: grey[200],
          "&:hover": {
            backgroundColor: grey[300],
            boxShadow: "none",
          },
          color: textColor,
        },
      ]}
      disabled={disabled}
      variant="contained"
      startIcon={matches && Boolean(Icon) ? CustomIcons[Icon] : ""}
      onClick={onClick}
    >
      {matches ? capitalizeWords(label) : Boolean(Icon) && CustomIcons[Icon]}
    </StyledButton>
  );
};

// upload button
export const Uploadbutton = ({ onClick, customStyles = {} }) => {
  const matches = useMediaQuery("(min-width:600px)");

  return (
    <StyledButton
      sx={[
        matches && customStyles,
        {
          width: matches ? "100px" : "40px",
          height: "33px",
          backgroundColor: Colors?.Mblue,
          "&:hover": {
            backgroundColor: Colors?.MHoverblue,
            boxShadow: "none",
          },
        },
      ]}
      // variant="contained"
      startIcon={
        matches && (
          <IoCloudUpload
            style={{
              fontSize: !matches ? 25 : 18,
              fontWeight: !matches && 900,
            }}
          />
        )
      }
      onClick={onClick}
    >
      {matches ? (
        "Upload"
      ) : (
        <IoCloudUpload
          style={{ fontSize: !matches ? 25 : 18, fontWeight: !matches && 900 }}
        />
      )}
    </StyledButton>
  );
};

export const CustomScrollbar = ({ children }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <Box
      sx={{
        "&::-webkit-scrollbar": {
          width: "8px",
          transition: "opacity 0.5s ease",
          opacity: hovered ? 1 : 0,
        },
        "&::-webkit-scrollbar-thumb": {
          background: "#888",
          borderRadius: "50px",
        },
        "&::-webkit-scrollbar-track": {
          background: "#f1f1f1",
        },
        "&::-webkit-scrollbar-thumb:hover": {
          background: "#555",
        },
        height: "100vh",
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
    </Box>
  );
};

// -------------------------------------- icons section --------------------------------------
export const EditIcon = ({
  color = "#7ac6a4",
  onClick,
  disabled = false,
  toolTip = false,
}) => (
  <CustomTooltip value={"Edit"} type={"children"} placement="bottom-start">
    <IconButton disabled={disabled} onClick={onClick} sx={{ p: 0.8 }}>
      <BiEdit style={{ fontSize: 16, color: color }} />
    </IconButton>
  </CustomTooltip>
);

export const DeletedIcon = ({
  color = "#f07167",
  onClick,
  disabled = false,
  toolTip = false,
}) => (
  <CustomTooltip value={"Delete"} type={"children"} placement="bottom-start">
    <IconButton disabled={disabled} onClick={onClick} sx={{ p: 0.8 }}>
      <MdDelete style={{ fontSize: 16, color: color }} />
    </IconButton>
  </CustomTooltip>
);

export const UpdateIcon = ({
  color = "#7ac6a4",
  onClick,
  disabled = false,
  toolTip = false,
}) => (
  <CustomTooltip value={"Update"} type={"children"} placement="bottom-start">
    <IconButton disabled={disabled} onClick={onClick} sx={{ p: 0.8 }}>
      <MdSave style={{ fontSize: 16, color: color }} />
    </IconButton>
  </CustomTooltip>
);

export const CustomIcon = ({
  color = "#95a0c5",
  onClick,
  Icon = CustomIcons?.viewIcon,
  disabled = false,
  toolTip = false,
}) => (
  <CustomTooltip
    value={(toolTip && "Overview") || ""}
    type={(toolTip && "children") || ""}
    placement="bottom-start"
  >
    <IconButton
      disabled={disabled}
      onClick={onClick}
      sx={{ m: "0px !important", p: "5px !important", color: color }}
    >
      {CustomIcons[Icon]}
    </IconButton>
  </CustomTooltip>
);

// -------------------------------------- Dialog Box ------------------------------------------

/* close dialog box */

const defaultContent =
  "CRMFarm found that some modification has been made. By cancelling the modification will not be saved";
export const DialogBox = ({
  onClickYes,
  disabled = false,
  onClickNo,
  open,
  Title = "Warning",
  Content = defaultContent,
  Subcontent = "",
  Icon1 = "checkIcon",
  Icon2 = "closeIcon",
  Label1 = "Yes",
  Label2 = "No",
  BtnWidth1 = "70px",
  BtnWidth2 = "70px",
  Buttons = true,
  maxWidth = "",
  fullWidth = false,
  isLoading = false
}) => {
  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      xs={12}
    >
      <DialogTitle
        sx={[
          dialogheading,
          DisplayFlex,
          { height: "38px !important", margin: "0px !important" },
        ]}
      >
        {Title == "Warning" && (
          <CiWarning
            style={{
              color: colorCode?.textDanger.color,
              fontSize: 22,
              transform: "translateX(-10px)",
            }}
          />
        )}
        <Typography
          id="Typography-root"
          sx={[
            {
              fontSize: "14px !important",
              fontWeight: "600 !important",
              transform: "translateX(-10px)",
            },
          ]}
        >
          {Title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: !Boolean(maxWidth) ? "380px !important" : "100%",
          fontSize: FontStyle.fontSize,
          fontWeight: Title !== "Warning" ? 300 : FontStyle.fontWeight,
          lineHeight: 2,
          backgroundColor: Colors?.dialogBgColor,
        }}
      >
        <Box
          sx={{
            minHeight: "60px",
            maxHeight: "100px",
            backgroundColor: "transparent !important",
          }}
        >
          <Typography sx={[FontStyle, { mt: 1 }]}>{Content}</Typography>
          <Typography sx={[FontStyle, { color: "#EA7135 !important", my: 2 }]}>
            {Subcontent}
          </Typography>
        </Box>

        {Buttons && (
          <Box
            sx={[
              DisplayFlex,
              {
                justifyContent: "center",
                gap: "10px !important",
                flexWrap: "wrap",
              },
            ]}
          >
            <Div>
              {Boolean(Label1) && (
                <CustomButton
                  disabled={disabled}
                  width={BtnWidth1}
                  label={Label1}
                  Icon={Icon1}
                  onClick={onClickYes}
                  isLoading={isLoading}
                  sx={{ mr: 1, height: "28px !important" }}
                />
              )}
              {Boolean(Label2) && (
                <Cancelbutton
                  disabled={disabled}
                  width={BtnWidth2}
                  Icon={Icon2}
                  label={Label2}
                  sx={{ height: "28px !important" }}
                  onClick={onClickNo}
                />
              )}
            </Div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

// Upload dialog box

// -------------------------------------- Auto complete ----------------------------------------
const CustomPopper = styled(Popper)(({ theme }) => ({
  "& .MuiAutocomplete-listbox": [FontStyle, {}],
}));

export const SelectDropdown = ({
  options = [],
  onChange,
  placeholder = "",
  value,
  getOptionLabel,
  defaultValue,
  clearButton = true,
  border = true,
  name,
  sx,
  className = "search-select3",
  multiple = false,
  width = "200px",
}) => (
  <Autocomplete
    multiple={multiple}
    limitTags={1}
    disablePortal
    className={className}
    value={value}
    defaultValue={defaultValue}
    options={options}
    onChange={onChange}
    name="name"
    sx={[
      FontStyle,
      sx,
      { width: width, height: "34px !important", cursor: "pointer" },
    ]}
    getOptionLabel={(option) => {
      if (typeof option === "string") {
        return option;
      }
      if (Boolean(option) && option[getOptionLabel]) {
        return option[getOptionLabel];
      }
      return "";
    }}
    renderInput={(params) => (
      <TextField
        sx={[FontStyle, sx]}
        placeholder={placeholder}
        {...params}
        InputProps={{
          ...params.InputProps,
          margin: 2,
          endAdornment: clearButton == false ? null : true, // Remove the caret
        }}
      />
    )}
  />
);

const gifIcons = {};
export const LoadingFullPageSkel = ({ Icon = "" }) => {
  return (
    <Div
      sx={{
        position: "absolute",
        zIndex: 999999,
        backgroundColor: alpha(Colors?.Mblue, 0.1),
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        ml: { md: "-50px ", xs: "-20px" },
        mt: { md: "-50px ", xs: "-20px" },
      }}
    >
      <Div sx={{ width: { md: "100px", xs: "70px" } }}></Div>
    </Div>
  );
};

// -------------------------------------- No Records page ------------------------------------------
export const NoRecordsPage = ({
  title = "",
  minWidth = "100px",
  minHeight = "100px",
  width = "27%",
  height = "25%",
}) => (
  <>
    <Div sx={{ width: "100%" }}>
      <Div
        sx={[DisplayFlex, { justifyContent: "center", alignItems: "center" }]}
      >
        <img
          style={{
            minWidth: minWidth,
            minHeight: minHeight,
            width: width,
            height: height,
          }}
          src={require("../../assets/images/No_record2.png")}
          alt="No Found"
        />
      </Div>
      <Div>
        <Typography sx={[{ textAlign: "center", fontSize: "12px" }]}>
          No {title} record.
        </Typography>
      </Div>
    </Div>
  </>
);

// -------------------------------------- Countup function ------------------------------------------
const useCounter = (minimum = 0, maximum, speed) => {
  const [count, setCount] = useState(minimum);

  useEffect(() => {
    const timer = setInterval(() => {
      setCount((prevCount) => {
        if (prevCount < maximum) {
          return prevCount + 1;
        } else {
          clearInterval(timer);
          return prevCount;
        }
      });
    }, speed);

    return () => clearInterval(timer);
  }, [minimum, maximum]);

  return count;
};

const Counter = ({ minimum = 0, maximum, speed = 50 }) => {
  let speedy =
    speed > 100
      ? 40
      : speed > 400
        ? 30
        : speed > 800
          ? 20
          : speed > 1500
            ? 10
            : 5;
  const count = useCounter(minimum, maximum, speedy);
  return (
    <>
      {count} <span style={{ fontSize: "11px" }}>Nos</span>
    </>
  );
};

export default Counter;

// -------------------------------------- Avatar Circle ------------------------------------------
export const AvatarCircle = ({
  fontSize = "10px",
  size = "25px",
  title = "",
  bgColor = Colors?.Mblue,
  boxShadow = "0px 0px 8px 0px #f9fafe",
  textColor = "white",
  textAlign = "center",
  fullTitle = false,
  label = "",
  labelSize = "12px",
  labelColor = Colors?.Mblue,
  subLabel = "",
  subLabelColor = "black",
  subLabelSize = "11px",
  gap = 0,
  image = false,
  onClick,
  marginTop,
}) => {
  const lightenColor = (color, percentage) => {
    const num = parseInt(color.slice(1), 16);
    const amt = Math.round(2.55 * percentage);
    const R = (num >> 16) + amt;
    const G = ((num >> 8) & 0x00ff) + amt;
    const B = (num & 0x0000ff) + amt;

    return `rgba(${Math.min(R, 255)}, ${Math.min(G, 255)}, ${Math.min(
      B,
      255
    )}, 0.5)`;
  };

  const lightBoxShadow =
    `0px 0px 8px 0px ${lightenColor(bgColor, 40)}` || boxShadow;
  return (
    <>
      <Div onClick={onClick} sx={{ marginTop: marginTop }}>
        <FormControlLabel
          sx={{ m: 0, ml: 0.1 }}
          control={
            <Avatar
              src={image}
              sx={[
                AvatarFont,
                {
                  textAlign: textAlign,
                  background: bgColor,
                  width: size,
                  height: size,
                  color: textColor,
                  fontSize: fontSize,
                  justifyContent: textAlign,
                  // boxShadow: lightBoxShadow,
                  boxShadow: boxShadow,
                },
              ]}
            >
              {Boolean(title) && (
                <Typography
                  sx={[
                    {
                      fontSize: fontSize,
                      color: textColor,
                      textAlign: textAlign,
                      transform: Boolean(fullTitle)
                        ? "translateX(6px)"
                        : "translateX(0px)",
                    },
                  ]}
                >
                  {!Boolean(fullTitle)
                    ? title?.substring(0, 2)?.toUpperCase()
                    : title}
                </Typography>
              )}
            </Avatar>
          }
          label={
            label !== "" ? (
              <>
                <Typography
                  sx={{
                    color: labelColor,
                    fontSize: labelSize,
                    ml: 0.5,
                    mb: gap,
                  }}
                >
                  {label}
                </Typography>

                {Boolean(subLabel) && (
                  <Typography
                    sx={{
                      color: subLabelColor,
                      fontSize: subLabelSize,
                      ml: 0.5,
                      mt: gap,
                    }}
                  >
                    {subLabel}
                  </Typography>
                )}
              </>
            ) : (
              ""
            )
          }
        />
      </Div>
    </>
  );
};

export const ScrollheightForScreenSize = () => {
  const [scrollbarHeight, setScrollbarHeight] = useState([
    window.innerHeight,
    window.innerWidth,
  ]);

  useEffect(() => {
    const handleResize = () => {
      setScrollbarHeight([window.innerHeight, window.innerWidth]);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return scrollbarHeight;
};

//karthik add

export const CustomScrollBar = ({
  stateValue = true,
  childrens = <></>,
  fromDate = "",
  toDate = "",
  formName = "",
}) => {
  let [screenHeight, screenWidth] = ScrollheightForScreenSize();
  const CurrentDate = new Date().toJSON().slice(0, 10);

  if (stateValue) {
    return (
      <>
        <JumboScrollbar
          autoHeight={true}
          autoHideTimeout={4000}
          autoHeightMin={Math.round(screenHeight / 1.84)}
          autoHide={true}
          hideTracksWhenNotNeeded
          id="no-more-tables"
        >
          {childrens}
        </JumboScrollbar>
      </>
    );
  } else {
    return (
      <>
        <Div sx={{ px: 2 }}>
          <Typography
            sx={{
              fontWeight: 600,
              textAlign: "center",
              fontSize: "19px !important",
            }}
          >
            {formName} {" - "} Reports
          </Typography>
        </Div>
        <Div sx={{ display: "flex", fontSize: "14px", mt: 3, px: 2 }}>
          <Div item className="col-4" sx={{ textAlign: "left" }}>
            Date : {CurrentDate}
          </Div>
          <Div item className="col-4" sx={{ textAlign: "left" }}>
            From : {fromDate}
          </Div>
          <Div item className="col-4" sx={{ textAlign: "left" }}>
            To : {toDate}
          </Div>
        </Div>
        <Div sx={{ px: 1 }}>{childrens}</Div>
      </>
    );
  }
};

// Use Query
export const InvalidateQueryUpdates = (queryKey) => {
  const queryClient = useQueryClient();
  queryClient.invalidateQueries(queryKey);
};

// colors func
export const avatarColorFun = (index = 0) =>
  index >= 10 ? avatarColors[index % 10] : avatarColors[index];

// <--------------------------------------------- Date format functions ------------------------------------------>
export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const options = { month: "long", day: "numeric", year: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

// <--------------------------------------------- Dragable dialog box ------------------------------------------>

function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      sx
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const Dragabledialog = ({
  open,
  onClose,
  children,
  dragable = true,
  maxWidth,
}) => {
  return (
    <Dialog
      maxWidth={maxWidth}
      fullWidth
      open={open}
      onClose={onClose}
      PaperComponent={dragable ? PaperComponent : ""}
      aria-labelledby="draggable-dialog-title"
      sx={{ borderRadius: "10px !important" }}
    >
      {children}
    </Dialog>
  );
};

// <--------------------------------------------- Error Functins ------------------------------------------>

export const errorFilter = (err) => {
  let error = {};

  if (err && typeof err === "object" && !Array.isArray(err)) {
    Object.keys(err)?.forEach((field) => {
      if (typeof err[field] === "string" && !err[field].trim()) {
        error[field] = field;
      }
    });
  }

  console.log("error", err, error);
  return error;
};

// <--------------------------------------------- Custom Grid ------------------------------------------>
export const Customgrid = memo(
  ({ children, columnCount, staticColSpan = false }) => {
    // Calculate the column sizes for odd and even indexed children
    return (
      <Grid container spacing={1}>
        {React.Children.map(children, (child, index) => {
          let colSpan = child.props.colSpan;

          if (colSpan === undefined) {
            if (index % 2 === 0) {
              colSpan = columnCount === 4 ? 2 : 2.5;
            } else {
              colSpan = columnCount === 4 ? 4 : 9.5;
            }
          }

          // Override with staticColSpan if provided
          if (staticColSpan) {
            colSpan = columnCount;
          }
          return (
            <Grid item xs={12} md={colSpan} key={index}>
              {React.cloneElement(child)}
            </Grid>
          );
        })}
      </Grid>
    );
  }
);
// <--------------------------------------------- Custom CKEditor ------------------------------------------------>
export const CustomCKEditor = ({ onChange, initData }) => {
  return (
    <CKEditor
      editor={ClassicEditor}
      data={initData}
      onChange={(event, editor) => {
        const data = editor.getData();
        onChange(data);
      }}
    />
  );
};
// <--------------------------------------------- parseStringArray ------------------------------------------------>
export function parseStringArray(str) {
  // Replace single quotes with double quotes
  const jsonString = str.replace(/'/g, '"');
  // Parse the JSON string to a JavaScript array
  const array = JSON.parse(jsonString);
  return array;
}

// <--------------------------------------------- Locale string amount format ------------------------------------------------>
export const toLocaleCurrency = (
  value = 0,
  currencyCode = "INR",
  locale = "en-US"
) => {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currencyCode,
  }).format(value);
};

//ckEditorOnchange
export const ckEditorOnchange = (evt, editor, setstate) => {
  const value = editor.getData();
  setstate(value);
};

// <--------------------------------------------- Fetch Transaction Series ------------------------------------------------>
export const fetchTransactionSeries = async (name = "") => {
  const OrganizationId = parseInt(localStorage.getItem("OrganizationId"));
  const token = localStorage.getItem("accesstoken");
  try {
    const response = await axios.get(
      `${BASE_URL}/useradmin/TransactionbyOrg/?Org_Id=${OrganizationId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    console.log("responseTABLE", response?.data[name]?.Type);
    return response?.data[name]?.Type;
  } catch (error) {
    console.log("failed to fetch transaction series", error);
    throw error;
  }
};

// <--------------------------------------------- format date and time ------------------------------------------------>

export const formatDateTime = ({ dateTimeString, type = "time" }) => {
  const date = Boolean(dateTimeString) ? new Date(dateTimeString) : new Date();

  const options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = new Intl.DateTimeFormat("en-US", options)?.format(date);

  // Format the date to 'YYYY-MM-DD HH:MM AM/PM'

  const [month, day, year] = formattedDate?.split(", ")[0]?.split("/");
  const time = formattedDate?.split(", ")[1];

  if (type === "time") {
    return `${time}`;
  } else if (type === "datetime") {
    return `${year}-${month}-${day} ${" "}  ${" "} ${time}`;
  } else {
    return `${year}-${month}-${day}`;
  }
};

// <--------------------------------------------- format date and time ------------------------------------------------>

export const defaultFormatDate = (date, dateFormat) => {
  return format(new Date(date), dateFormat);
};

// <--------------------------------------------- format date and time ------------------------------------------------>

export const TextEditor = ({ value = "", onChange }) => {
  return (
    <ReactQuill
      value={value}
      onChange={onChange}
      modules={{
        toolbar: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
        ],
        clipboard: {
          matchVisual: false,
        },
      }}
      formats={[
        "header",
        "font",
        "size",
        "bold",
        "italic",
        "underline",
        "strike",
        "blockquote",
        "list",
        "bullet",
        "indent",
        "link",
        "image",
        "video",
      ]}
    />
  );
};

// Customize Dialog Box
const defaultContentcampaign =
  "CRMFarm found that some modification has been made. By cancelling the modification will not be saved";
export const DialogBoxCampaign = ({
  onClickYes,
  disabled = false,
  onClickNo,
  open,
  Title = "Warning",
  Content = defaultContentcampaign,
  Subcontent = "",
  Icon1 = "checkIcon",
  Icon2 = "closeIcon",
  Label1 = "Yes",
  Label2 = "Cancel",
  BtnWidth1 = "70px",
  BtnWidth2 = "90px",
  Buttons = true,
  maxWidth = "",
  fullWidth = false,
}) => {
  // alert(Boolean(maxWidth))
  return (
    <Dialog
      open={open}
      // onClose={() => { setSave(false) }}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      xs={12}
    // sx={{width:"300px !important"}}
    >
      <DialogTitle
        sx={[
          dialogheading,
          DisplayFlex,
          { height: "38px !important", margin: "0px !important" },
        ]}
      >
        {Title == "Warning" && (
          <CiWarning
            style={{
              color: colorCode?.textDanger.color,
              fontSize: 22,
              transform: "translateX(-10px)",
            }}
          />
        )}
        <Typography
          id="Typography-root"
          sx={[
            {
              fontSize: "14px !important",
              fontWeight: "600 !important",
              transform: "translateX(-10px)",
            },
          ]}
        >
          {Title}
        </Typography>
      </DialogTitle>
      <DialogContent
        sx={{
          width: !Boolean(maxWidth) ? "380px !important" : "100%",
          fontSize: FontStyle.fontSize,
          fontWeight: Title !== "Warning" ? 300 : FontStyle.fontWeight,
          lineHeight: 2,
          backgroundColor: Colors?.dialogBgColor,
        }}
      >
        <Box
          sx={{
            minHeight: "80px",
            backgroundColor: "transparent !important",
            // py: 1.5,
          }}
        >
          <Typography sx={[FontStyle, { mt: 1 }]}>{Content}</Typography>
          <Typography sx={[FontStyle, { color: "#EA7135 !important", my: 2 }]}>
            {Subcontent}
          </Typography>
        </Box>
        {Buttons && (
          <Box
            sx={[
              DisplayFlex,
              {
                justifyContent: "end",
                gap: "10px !important",
                // mt: 1.5,
                flexWrap: "wrap",
              },
            ]}
          >
            <Div>
              {Boolean(Label2) && (
                <CustomButton
                  onClick={onClickNo}
                  label={Label2}
                  width={BtnWidth2}
                  Icon={Icon2}
                  disabled={disabled}
                  sx={{ p: 2 }}
                />
                // <Cancelbutton
                //   disabled={disabled}
                //   width={BtnWidth2}
                //   Icon={Icon2}
                //   label={Label2}
                //   sx={{ height: "26px !important" }}
                //   onClick={onClickNo}
                // />
              )}
            </Div>
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

// <--------------------------------------------- Countdown functions ------------------------------------------------>
export const countdown = (start) => {
  let current = start;

  // Validate that start is a non-negative number
  if (typeof start !== "number" || start < 0) {
    console.error("Invalid start value. Please provide a non-negative number.");
    return;
  }

  const interval = setInterval(() => {
    console.log(current);
    if (current <= 0) {
      clearInterval(interval);
    } else {
      return current--;
    }
  }, 1000); // 1000 milliseconds = 1 second
};

// FirstLetterCaps
export const FirstLetterCaps = (str) => {
  if (typeof str !== "string" || str === null || str === undefined) {
    return str
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};


export const CustomTableSkeleton = memo(({ rowCount = 10, headCount = 8 }) => {
  console.log("skeleton calling from styeld");
  return (
    <>
      {Array(rowCount)
        ?.fill()
        ?.map((item, index) => (
          <TableRow key={index + 1}>
            {Array(headCount)
              ?.fill()
              ?.map((data) => (
                <TableCell>
                  <Skeleton height={30} />
                </TableCell>
              ))}
          </TableRow>
        ))}
    </>
  );
});

export const CustomTooltip = memo(
  ({
    value = "",
    char = 10,
    type = "text",
    placement = "bottom-start",
    children,
  }) => {
    console.log("CustomTooltip calling from styeld");

    const textTooltip = (
      <Tooltip
        title={value?.length > char ? FirstLetterCaps(value) : ""}
        placement={placement}
      >
        <span>
          {FirstLetterCaps(
            value &&
            (value?.length > char ? value?.slice(0, char) + "..." : value)
          ) || "-"}
        </span>
      </Tooltip>
    );

    const childrenTooltip = (
      <Tooltip title={value} placement={placement}>
        {children || "-"}
      </Tooltip>
    );

    return type === "text" ? textTooltip : childrenTooltip;
  }
);

// export const errorRequired = (err) => {
//   const formatString = (str) => {
//     str = str.replace(/_/g, " ");
//     str = str.replace(/([a-z])([A-Z])/g, "$1 $2");
//     return str.charAt(0).toUpperCase() + str.slice(1);
//   };
//   let error = {};
//   if (err && typeof err === "object" && !Array.isArray(err)) {
//     Object.keys(err)?.forEach((field) => {
//       if (typeof err[field] === "string" && !Boolean(err[field].trim())) {
//         error[field] = `${formatString(field)} is required.`;
//       } else if (typeof err[field] === "number" && !Boolean(err[field])) {
//         error[field] = `${formatString(field)} is required.`;
//       }
//     });
//   }
//   console.log("errorRequired", error);
//   return error;
// };

export const errorRequired = (err) => {
  const formatString = (str) => {
    str = str.replace(/_/g, " ");
    str = str.replace(/([a-z])([A-Z])/g, "$1 $2");
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const isValidPhone = (phone) => {
    const phoneRegex = /^\d{10}$/; // Ensures exactly 10 digits
    return phoneRegex.test(phone);
  };
  let error = {};
  if (err && typeof err === "object" && !Array.isArray(err)) {
    Object.keys(err)?.forEach((field) => {
      if (typeof err[field] === "string" && !Boolean(err[field].trim())) {
        error[field] = `${formatString(field)} is required.`;
      } else if (typeof err[field] === "number" && !Boolean(err[field])) {
        error[field] = `${formatString(field)} is required.`;
      } else if (field === "Mail" && !isValidEmail(err[field])) {
        error[field] = `${formatString(field)} must be a valid email address.`;
      } else if (field === "Phone" && !isValidPhone(err[field])) {
        error[field] = `${formatString(field)} must be exactly 10 digits.`;
      }
      if (field === "selectedCountry1" && !Boolean(err[field])) {
        error[field] = "Country is required.";
      }
      if (field === "selectedState1" && !Boolean(err[field])) {
        error[field] = "State is required.";
      }
      if (field === "selectedCity1" && !Boolean(err[field])) {
        error[field] = "City is required.";
      }
    });
  }
  console.log("errorRequired", error);
  return error;
};

export const StyledEditorWrapper = styledComponents.div`
  .ck-editor__editable {
    min-height: ${({ minHeight }) => minHeight || "200px"} !important;
    color: ${({ color }) => color || "black"} !important;
    font-size: "14px";
  }
`;

export const htmlToText = (html = "") => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = html;
  return tempDiv.textContent || tempDiv.innerText || "";
};

export const customDateTime = (dateString) => {
  return moment(dateString).format("DD-MM-YYYY hh:mm A");
};

export const DateFormatter = ({
  date = new Date().toISOString().split("T")[0],
}) => {
  const dispatch = useDispatch();
  const dateFormat = useSelector(selectedDateFormat);
  const correctedData = date?.slice(0, 10);

  // Memoize the formatted date value based on the date and date format
  const formattedDate = React.useMemo(() => {
    if (dateFormat && correctedData) {
      try {
        return format(new Date(correctedData), dateFormat);
      } catch (error) {
        console.error("Date formatting error:", error);
        return correctedData; // Fallback to the original date if formatting fails
      }
    }
    return correctedData;
  }, [correctedData, dateFormat]);

  return <span>{formattedDate || correctedData}</span>;
};

export const CRMDateField = ({
  value,
  onChange,
  type,
  InputProps: parentInputProps,
  ...props
}) => {
  const [showDateField, setShowDateField] = useState(false);
  const dateFormat = useSelector(selectedDateFormat);
  const dateFieldRef = React.useRef(null);
  const handleBlurDateField = React.useCallback((event) => {
    if (dateFieldRef.current && !dateFieldRef.current.contains(event.target)) {
      setShowDateField(false);
    }
  }, []);
  useEffect(() => {
    document.addEventListener("click", handleBlurDateField, true);
    return () => {
      document.removeEventListener("click", handleBlurDateField, true);
    };
  }, [handleBlurDateField]);

  const dateType = showDateField ? (type ? type : "date") : "text";

  const formatDate = (date, formatString, type) => {
    try {
      const parsedDate = new Date(date);
      if (type === "datetime-local") {
        return format(parsedDate, `${formatString} HH:mm`);
      } else if (type === "date") {
        return format(parsedDate, formatString);
      }
      return format(parsedDate, formatString);
    } catch (error) {
      console.error("Date formatting error:", error);
      return date;
    }
  };

  const formattedValue =
    !showDateField && value ? formatDate(value, dateFormat, type) : value;

  // Merge default InputProps with those from the parent
  const mergedInputProps = {
    ...parentInputProps,
    inputProps: {
      ...(parentInputProps?.inputProps || {}),
      min: parentInputProps?.inputProps?.min, // or any other specific props
      max: props?.inputProps?.max,
    },
    endAdornment: !showDateField && (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle calendar"
          onMouseEnter={() => setShowDateField(true)}
          edge="end"
          sx={{ pl: 0, pr: 1.28, mt: -0.2 }}
        >
          <MdCalendarToday
            size={13}
            color={Boolean(props?.disabled) ? "gray" : "#000"}
          />
        </IconButton>
      </InputAdornment>
    ),
  };

  return (
    <TextField
      {...props}
      type={dateType}
      className={
        props?.className?.match(/\binput-box\b/)
          ? props?.className?.replace(/\binput-box\b/, "input-box2")
          : props?.className || "col-12 input-box2"
      }
      onFocus={() => setShowDateField(true)}
      onBlur={() => setShowDateField(false)}
      value={formattedValue || ""}
      onChange={(e) => {
        onChange(e);
      }}
      ref={dateFieldRef}
      placeholder={dateFormat}
      InputProps={mergedInputProps}
      sx={{
        "& input": {
          p: "10px !important",
          width: "100% !important",
          minWidth: "100px !important",
        },
        "& input::placeholder": {
          color: Boolean(props?.disabled) ? "gray" : "#000", // Adjust the color as needed
          opacity: Boolean(props?.disabled) ? 0.5 : 0.8, /// Adjust the color as needed
          marginTop: "3.5px",
        },
        "&:focus-within .MuiOutlinedInput-notchedOutline": {
          borderColor: "#F3F3F3", // Adjust the border color as needed
        },
      }}
    />
  );
};

export const CRMCustomLoader = () => {
  return (
    <JumboContentLayoutMain>
      <Div
        sx={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </Div>
    </JumboContentLayoutMain>
  );
};

export const CRMSeparateDateTime = (timestamp) => {
  const dispatch = useDispatch();
  const dateFormat = useSelector(selectedDateFormat);

  useEffect(() => {
    dispatch(fetchDateFormater());
  }, [dispatch]);

  // Ensure timestamp is a string or handle Date objects
  const correctedData = React.useMemo(() => {
    if (!timestamp) return "";

    return typeof timestamp === "string"
      ? timestamp.slice(0, 10)
      : timestamp instanceof Date
        ? timestamp.toISOString().slice(0, 10)
        : "";
  }, [timestamp]);

  // Memoize the formatted date value based on the date and date format
  const formattedDate = React.useMemo(() => {
    if (dateFormat && correctedData) {
      try {
        return format(new Date(correctedData), dateFormat);
      } catch (error) {
        console.error("Date formatting error:", error);
        return correctedData;
      }
    }
    return correctedData;
  }, [correctedData, dateFormat]);

  const dateObject = React.useMemo(() => {
    return typeof timestamp === "string" ? new Date(timestamp) : timestamp;
  }, [timestamp]);

  if (!timestamp) {
    return "";
  }

  const timePart = dateObject.toLocaleTimeString("en-GB");
  const dateTimeString = `${formattedDate} ${timePart}`;

  return dateTimeString;
};

export const CommonPagination = ({
  totalPages,
  page = 1,
  onChange = () => { },
}) => {
  return (
    <Grid
      container
      spacing={1}
      sx={{ mt: 2, mb: 2, display: "flex", alignItems: "center" }}
    >
      <Grid item xs={12} md={0} lg={3} xl={4}></Grid>
      <Grid
        item
        xs={12}
        md={4}
        lg={4}
        xl={3}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          alignItems: "center",
          gap: "10px !important",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={8}
        lg={5}
        xl={5}
        sx={{
          display: "flex",
          justifyContent: { xs: "center", md: "flex-end" },
          alignItems: "center",
          gap: "10px !important",
        }}
      >
        <Pagination
          color="primary"
          count={totalPages}
          page={page}
          onChange={(e, newValue) => onChange(e, newValue)}
          variant="outlined"
          shape="rounded"
          showFirstButton
          showLastButton
        />
      </Grid>
    </Grid>
  );
};

export const CRMCustomScrollTable = ({
  datas,
  scrollRef,
  scrollHeight = AutoHeight(1.65),
  children,
  ...props
}) => {
  return (
    <Grow in={true} style={{ transformOrigin: "0 0 0" }} timeout={1000}>
      <Div
        className="crmscrolltable"
        sx={{
          mb: 1,
          backgroundColor: "#52453317 !important",
          padding: "0px 30px 0px 30px !important",
          borderRadius: "5px !important",
        }}
      >
        <CustomScrollbarBox ref={scrollRef} scrollHeight={scrollHeight}>
          {children}
        </CustomScrollbarBox>
      </Div>
    </Grow>
  );
};

export const CRMCustomScrollCard = ({
  setFadeEdit = false,
  index,
  children,
}) => {
  return (
    <Div
      onMouseOver={() => (setFadeEdit ? setFadeEdit(index) : {})}
      onMouseLeave={() => (setFadeEdit ? setFadeEdit(null) : {})}
      className=""
      key={index}
      sx={{
        position: "relative",
        minHeight: "115px",
        p: { xs: 0.5, sm: 1.5 },
        px: { xs: 0.5, md: 7 },
        my: 2,
        bgcolor: "white",
        borderRadius: { xs: "5px !important", md: "10px !important" },
        scrollBehavior: "smooth",
        "&:hover": {
          backgroundColor: "#fcfefd",
          transform: "scale(0.5)",
          borderLeft: {
            xs: `4px solid ${Colors?.Mblue}`,
            md: `6px solid ${Colors?.Mblue}`,
          },
          transition: "all 0.1s ease-in-out", // Smooth transition for all properties

          // Add some subtle shadow animation
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          transform: "translateY(-2px)",
        },
        cursor: "default",
      }}
    >
      {children}
    </Div>
  );
};

export const CustomScrollbarBox = styled(Box)(
  ({
    scrollWidth = "100%",
    scrollHeight = "100%",
    overflowX = "hidden",
    overflowY = "hidden",
  }) => ({
    width: scrollWidth,
    height: scrollHeight,
    overflowX: overflowX,
    overflowY: overflowY,
    position: "relative",
    transition: "overflow 0.3s linear",
    scrollBehavior: "smooth",
    "&:hover": {
      overflowX: "auto",
      overflowY: "auto",
    },
    "&::-webkit-scrollbar": {
      height: true ? "7px" : "0px",
      width: true ? "7px" : "0px",
      transition: "opacity 0.3s ease",
      opacity: 0,
    },
    "&:hover::-webkit-scrollbar": {
      opacity: 1,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#D2D2D2",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#D2D2D2",
      cursor: "grabbing",
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#EFEEED",
    },
    "&::-webkit-scrollbar:horizontal": {
      height: true ? "7px" : "0px",
      width: "7px",
    },
    "&::-webkit-scrollbar:vertical": {
      width: true ? "7px" : "0px",
      height: "7px",
    },
  })
);

const bellShake = keyframes`
  0% { transform: rotate(0); }
  25% { transform: rotate(-15deg); }
  50% { transform: rotate(15deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0); }
`;

const BellIcon = styled("i")`
  margin-left: 8px;
  animation: ${({ animate }) =>
    animate ? `${bellShake} 0.8s ease-in-out` : "none"};
`;

export const CRMCustomBellIcon = () => {
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    setAnimate(true);
    const timer = setTimeout(() => setAnimate(false), 800);
    return () => clearTimeout(timer);
  }, []);

  return <BellIcon className="bi bi-bell-fill" animate={animate} />;
};

export const cleanData = (value) => {
  if (typeof value === "string") {
    const cleanedValue = value
      .replace(/(\r\n•|\r\n|\n•|\r•|\•)/g, "")
      .replace(/<\/?[^>]+(>|$)/g, "")
      .trim()
    return FirstLetterCaps(cleanedValue);
  }
  return FirstLetterCaps(value);
};

export const useCRMInfiniteScroll = (status, count, setPage) => {
  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [loading, setLoading] = useState(false);
  const scrollRef = React.useRef();

  const handleScroll = React.useCallback(() => {
    if (status) return;

    const scrollTop =
      scrollRef.current.scrollTop || document.documentElement.scrollTop;
    const divHeight = scrollRef.current.clientHeight;
    const scrollHeight = scrollRef.current.scrollHeight;

    if (
      scrollTop > prevScrollTop &&
      scrollTop + divHeight >= scrollHeight - 1 &&
      count > 0
    ) {
      setLoading(true);
      setPage((prevPage) => prevPage + 1);
    }
    setPrevScrollTop(scrollTop);
  }, [prevScrollTop, status, count]);

  useEffect(() => {
    const divElement = scrollRef.current;
    if (divElement) {
      divElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (divElement) {
        divElement.removeEventListener("scroll", handleScroll);
        setLoading(false);
      }
    };
  }, [handleScroll]);

  return { scrollRef, loading };
};

export const ErrorBoundary = ({ children }) => {
  const [hasError, setHasError] = useState(false);

  const handleError = () => {
    setHasError(true);
  };

  useEffect(() => {
    // Set up an error listener
    const errorListener = (error) => {
      handleError();
    };

    window.addEventListener("error", errorListener);

    // Clean up the listener on unmount
    return () => {
      window.removeEventListener("error", errorListener);
    };
  }, []);

  if (hasError) {
    return <h1>Something went wrong.</h1>;
  }

  return children;
};

// export const handleError = (error) => {
//   if (error.response?.data) {
//     const errorData = error.response?.data;
//     Object.keys(errorData).forEach(key => {
//       const errorMessage = errorData[key];
//       console.log("errorMessage",errorMessage)
//       if (Array.isArray(errorMessage)) {
//         errorMessage.forEach(message => {
//           toast.error(message);
//         });
//       } else {
//         toast.error(`${errorMessage}`);
//       }
//     });
//   } else {
//     toast.error('An unexpected error occurred. Please try again later.');
//   }
// };


export const handleError = (error) => {
  if (error.response?.data?.error?.message) {
    toast.error(error.response.data.error.message);
  } else if (error.response?.data) {
    const errorData = error.response?.data;
    Object.keys(errorData).forEach((key) => {
      const errorMessage = errorData[key];
      console.log("errorMessage", errorMessage);
      if (Array.isArray(errorMessage)) {
        errorMessage.forEach((message) => {
          toast.error(message);
        });
      } else {
        toast.error(`${errorMessage}`);
      }
    });
  } else {
    toast.error('An unexpected error occurred. Please try again later.');
  }
};


export const useStageVisibility = (items = [], delay = 100) => {
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    setVisibleItems([]); // Reset visibility when items change

    if (!items || items.length === 0) return;

    const timers = [];

    items.forEach((_, idx) => {
      const timer = setTimeout(() => {
        setVisibleItems((prev) => [...prev, idx]);
      }, idx * delay);
      timers.push(timer);
    });

    // Cleanup function to clear all timeouts when dependencies change
    return () => {
      timers.forEach(clearTimeout);
    };
  }, [items, delay]);

  return visibleItems;
};

export const CustomAudioPlayer = ({ audioSrc, showDownload = true }) => {
  const languages = [
    { id: 0, value: "en", stands: "English" },
    // { id: 1, value: "fr", stands: "French" },
    // { id: 2, value: "es", stands: "Spanish" },
  ];
  const [previewAudio, setPreviewAudio] = useState(audioSrc);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState("English"); // Default language selection
  const [selectedLanguageCode, setSelectedLanguageCode] = useState("en"); // Default language selection

  // Function to handle opening the modal
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // Function to handle language selection change
  const handleLanguageChange = async (e, newVal) => {
    setSelectedLanguage(newVal?.stands);

    console.log("audioSrc", audioSrc);
    try {
      const response = await fetch(
        `${BASE_URL}/convertlanguage/?external_url=${audioSrc}`
      );

      if (!response.ok) {
        throw new Error("Failed to fetch audio file");
      }

      const blob = await response.blob();
      // Create a file from the blob (MP3)
      const file = new File([blob], "audio-file.mp3", { type: "audio/mp3" });
      console.log("File created:", file);

      const formData = new FormData();
      formData.append("audio", file);
      formData.append("target_language", newVal?.value);

      // Make the API call to upload the file
      const apiResponse = await axios.post(
        `https://backend.crmfarm.in/user/audiofiles/`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      // Handle the response
      if (apiResponse?.data?.id) {
        const downloadUrl = apiResponse.data.translated_audio;
        setPreviewAudio(downloadUrl);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
    }
  };

  // Function to handle the download after language selection
  const handleDownloadCallRecord = async () => {
    let downloadUrl = previewAudio;

    try {
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.download = `audio-file-${selectedLanguage}.ogg`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.log(error);
    }
    handleCloseModal();
  };

  return (
    <>
      {audioSrc ? (
        <div style={{ display: "flex", alignItems: "center", gap: "20px" }}>
          {showDownload && (
            <audio
              controlsList="nodownload"
              controls
              style={{ padding: "7px" }}
            >
              <source src={audioSrc} type="audio/ogg" />
            </audio>
          )}

          {/* Custom Download Button */}
          <CustomButton
            onClick={handleOpenModal}
            label={"Download Audio"}
            type="button"
            Icon="downloadIcon"
            width={"170px"}
            disabled={audioSrc ? false : true}
            bgColor={audioSrc ? Colors?.Mblue : "#c7c7c7"}
          />

          {/* Language Selection Modal */}
          <Dialog open={isModalOpen} onClose={handleCloseModal}>
            <DialogTitle>Download Audio</DialogTitle>
            <DialogContent>
              <Div>
                <audio
                  controlsList="nodownload"
                  controls
                  style={{
                    padding: "5px",
                    width: { xs: "200px", md: "230px" },
                  }}
                >
                  <source src={previewAudio} type="audio/ogg" />
                </audio>
              </Div>
              <br />
              <Div
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <FormControl className="col-6">
                  <Autocomplete
                    className="search-select3"
                    options={languages}
                    getOptionLabel={(option) => {
                      if (typeof option === "string") {
                        return option;
                      }
                      if (option && option?.stands) {
                        return option?.stands;
                      }
                      return "";
                    }}
                    value={selectedLanguage || ""}
                    onChange={(e, newVal) => handleLanguageChange(e, newVal)}
                    id="outlined-basic"
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Select Language" />
                    )}
                  />
                </FormControl>

                <CustomButton
                  onClick={handleDownloadCallRecord}
                  label={"Download"}
                  type="button"
                  Icon="downloadIcon"
                  width={"130px"}
                />
              </Div>
            </DialogContent>
            <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
              <Cancelbutton label="Cancel" onClick={handleCloseModal} />
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          No Recordings
        </div>
      )}
    </>
  );
};

export const StyledIconButton = ({ onClick, children }) => {
  return (
    <IconButton
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: "#249593",
        },
      }}
    >
      {children}
    </IconButton>
  );
};

export const allowedFileTypes = [
  "application/pdf", // PDF
  "text/plain", // Text files
  "text/csv", // CSV
  "application/vnd.ms-excel", // Older Excel
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", // Newer Excel
];

export const MultiValueTextField = ({
  label,
  placeholder = "",
  onChange,
  className,
  values,
  setValues,
  labelName,
  options,
  setOptions,
}) => {
  const [inputValue, setInputValue] = useState("");
  const handleAddValue = (e) => {
    if (e.key === "Enter" && inputValue.trim()) {
      e.preventDefault(); // Prevent form submission on Enter
      setOptions([...options, inputValue]);
      const newValues = [
        ...values,
        {
          condition: `'${labelName}' == '${inputValue.trim()}'`,
          result: "",
        },
      ];
      setValues(newValues);
      setInputValue("");
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Box>
          <TextField
            className={className}
            placeholder={placeholder}
            variant="outlined"
            value={inputValue}
            onChange={(e) => setInputValue(e.target.value)}
            onKeyDown={handleAddValue}
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={4}></Grid>
    </Grid>
  );
};
// Prop validation
MultiValueTextField.propTypes = {
  label: PropTypes.string.isRequired, // Label for the input
  placeholder: PropTypes.string, // Placeholder text
  onChange: PropTypes.func, // Callback for when the values change
};
// Default props
MultiValueTextField.defaultProps = {
  placeholder: "Enter values",
  onChange: null,
};

export const readFileAsBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result.split(",")[1]);
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });

export const compressImageFile = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const base64String = reader.result;
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement("canvas");
        const maxWidth = 800;
        const maxHeight = 800;
        let width = img.width;
        let height = img.height;
        // Maintain aspect ratio
        if (width > height && width > maxWidth) {
          height = Math.round((height * maxWidth) / width);
          width = maxWidth;
        } else if (height > maxHeight) {
          width = Math.round((width * maxHeight) / height);
          height = maxHeight;
        }
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        const compressedBase64 = canvas
          .toDataURL("image/jpeg", 0.8)
          .split(",")[1];
        resolve(compressedBase64);
      };
      img.onerror = (err) => reject(err);
      img.src = base64String;
    };
    reader.onerror = (err) => reject(err);
    reader.readAsDataURL(file);
  });


const isEmpty = (value) => {
  return (
    value === null ||
    value === undefined ||
    value === "" ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
};

export const findEmptyFields = (response) => {
  const emptyFields = [];
  for (let key in response) {
    if (isEmpty(response[key])) {
      emptyFields.push(key);
    }
  }
  return emptyFields;
};

export const CustomGaugeChart = ({
  title,
  value,
  colors = [["#f6ddcc", "#e9f7ef"]],
}) => {
  const gaugeRef = React.useRef(null); // Reference to the GaugeChart container

  useEffect(() => {
    const needle = document.querySelector(".gauge-chart .gauge-needle");
    const needlePath = document.querySelector(".gauge-chart .gauge-path");

    if (needle) {
      // Set the width of the needle by modifying the style directly
      needle.style.width = "2px"; // Adjust the needle width as needed
    }

    if (needlePath) {
      // Modify the stroke width of the needle path
      needlePath.style.strokeWidth = "2px"; // Adjust the needle path width
    }
  }, []);

  const datas = {};

  return (
    <Div
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h3" align="center">
        {title}
      </Typography>
      <br />

      <GaugeChart
        id="gauge-chart"
        nrOfLevels={1}
        percent={value}
        // colors={["#f6ddcc", "#e9f7ef"]}
        colors={colors}
        arcWidth={0.1}
        needleColor="#0a4a5f"
        textColor="#000000"
        animate={true}
        ref={gaugeRef}
      />

      {/* <ReactSpeedometer
        width={150}
        maxValue={100}
        customSegmentLabels={false}
        value={value}
        segmentColors={["#a3be8c", "#bf616a"]}
        currentValueText={`${datas?.TotalLostCustomers > datas?.TotalGainedCustomers
          ? "-"
          : ""
          } ${(datas?.TotalLostCustomers /
            (datas?.TotalGainedCustomers || 1)) *
          100 || "0"
          }%`}
        textColor={"black"}
      /> */}
    </Div>
  );
};
export const CustomSpeedometer = ({
  title,
  data,
  label1,
  label2,
  totalCount, // Total apartments count
  availableCount, // Available apartments count
  colors = ["#f6ddcc", "#e9f7ef"], // Optional, define your colors
}) => {
  return (
    <Div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {/* Title */}
        <Div
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
          }}
        >
          {/* {item?.Attachfile || "-"} */}
          {
            <img
              alt="Selected"
              src={properlyImg}
              width={35}
              height={35}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "30%",
              }}
            />
          }
          <Typography variant="h3">{title}</Typography>
        </Div>
        {/* <Typography variant="h3">View All</Typography> */}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <ReactSpeedometer
          maxValue={totalCount}
          value={availableCount}
          needleColor="#0a4a5f"
          textColor={"#000000"}
          width={250}
          height={200}
          currentValueText={`${availableCount} / ${totalCount}`}
          segments={10} // More segments create a smoother gradient
          segmentColors={Array.from(
            { length: 10 },
            (_, i) =>
              `rgb(${109 + i * ((41 - 109) / 10)}, ${213 + i * ((128 - 213) / 10)
              }, ${250 + i * ((185 - 250) / 10)})`
          )}
          needleTransitionDuration={2000}
          needleTransition="easeElastic"
          segmentLabels={[]}
          labelFontSize="0px"
          needleHeightRatio={0.6}
        />

        {/* Display total and available apartment counts */}
        <div style={{ marginTop: "10px" }}>
          <Typography variant="h6">
            {label1}: {totalCount}
          </Typography>
          <Typography variant="h6">
            {label2}: {availableCount}
          </Typography>
        </div>
      </div>
    </Div>
  );
};
export const CustomcoworkingSpeedometer = ({
  title,
  data,
  totalCount, // Total count (sum of all spaces)
  availableCount, // Available count
}) => {
  const labels = [
    { key: "Total_Seats", label: "Total Seats" },
    { key: "Available_Seats", label: "Available Seats" },
  ];

  return (
    <Div>
      {/* Header Section */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Div sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <img
            alt="Selected"
            src={properlyImg}
            width={35}
            height={35}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "30%",
            }}
          />
          <Typography variant="h3">{title}</Typography>
        </Div>
      </div>

      {/* Speedometer Section */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <ReactSpeedometer
          maxValue={totalCount}
          value={availableCount}
          needleColor="#0a4a5f"
          textColor={"#000000"}
          width={250}
          height={200}
          currentValueText={`${availableCount} / ${totalCount}`}
          segments={10}
          segmentColors={Array.from(
            { length: 10 },
            (_, i) =>
              `rgb(${109 + i * ((41 - 109) / 10)}, ${213 + i * ((128 - 213) / 10)
              }, ${250 + i * ((185 - 250) / 10)})`
          )}
          needleTransitionDuration={2000}
          needleTransition="easeElastic"
          segmentLabels={[]}
          labelFontSize="0px"
          needleHeightRatio={0.6}
        />

        {/* Display all labels with counts */}
        <div
          style={{
            marginTop: "10px",
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
          }}
        >
          {labels.map(({ key, label }) => (
            <Typography key={key} variant="h6">
              {label}: {data?.[key] ?? 0}
            </Typography>
          ))}
        </div>
      </div>
    </Div>
  );
};


export const formatIndianNumberWithoutfloat = (number) => {
  if (isNaN(number)) {
    return 0; // Return a message for invalid input
  }
  const numericValue = Number(number); // Convert to a number if it's not already
  return new Intl.NumberFormat("en-IN").format(numericValue); // Format as Indian number format without decimals
};

export const GradientButton = ({ text, onClick, iconSrc }) => {
  const buttonStyle = {
    background: "linear-gradient(90deg, #b621fe, #1fd1f9)", // Gradient colors
    border: "none",
    color: "white",
    height: "33px",
    width: "100%",
    padding: "10px",
    fontSize: "16px",
    borderRadius: "25px",
    cursor: "pointer",
    transition: "all 0.3s ease",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex", // Enables flexbox
    alignItems: "center", // Centers content vertically
    justifyContent: "center", // Centers content horizontally
    gap: "10px", // Adds spacing between the icon and text
  };

  const hoverStyle = {
    background: "linear-gradient(45deg, #2575fc, #6a11cb, #08f2bc)", // Reverse gradient on hover
  };

  const [isHovered, setIsHovered] = React.useState(false);

  return (
    <button
      style={isHovered ? { ...buttonStyle, ...hoverStyle } : buttonStyle}
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {/* Display PNG Image as Icon */}
      {iconSrc && (
        <img
          src={iconSrc}
          alt="Icon"
          style={{
            height: "20px", // Adjust height as needed
            width: "20px", // Adjust width as needed
          }}
        />
      )}
      {text}
    </button>
  );
};

export const compressPdf = async (file) => {
  return new Promise((resolve, reject) => {
    import("jspdf").then((jsPDF) => {
      const reader = new FileReader();
      reader.onload = async () => {
        const arrayBuffer = reader.result;
        const pdf = new jsPDF();
        pdf.addImage(arrayBuffer, "JPEG", 0, 0);

        // Re-generate the PDF with new compression options
        const compressedPdfBytes = pdf.output("arraybuffer");
        const compressedBase64 = btoa(
          new Uint8Array(compressedPdfBytes).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        resolve(compressedBase64);
      };
      reader.onerror = (err) => reject(err);
      reader.readAsArrayBuffer(file);
    });
  });
};


export const NumericTextField = ({
  value = 0,
  onChange,
  name,
  id,
  placeholder,
  variant = "outlined",
  sx = { minWidth: "150px" },
  ...rest
}) => {

  return (
    <TextField
      type="number"
      className="col-md-12 input-box"
      name={name}
      id={id}
      value={value}
      onChange={onChange}
      variant={variant}
      placeholder={placeholder}
      inputProps={{
        inputMode: "decimal",
        pattern: "[0-9]*\.?[0-9]*",
        style: {
          MozAppearance: "textfield",
          WebkitAppearance: "none",
          appearance: "none",
          overflow: "hidden",
        },
        onWheel: (e) => e.preventDefault(),
        onKeyDown: (e) => {
          // Prevent default action for arrow keys to avoid scrolling
          if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
            e.preventDefault();
          }
        }
      }}
      autoComplete="off"
      sx={{
        ...sx,
        textAlign: "right",
        "& input[type=number]": {
          MozAppearance: "textfield",
          WebkitAppearance: "none",
          appearance: "none",
          overflow: "hidden",
        },
        "& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button": {
          WebkitAppearance: "none",
          margin: 0,
        },
      }}
      {...rest}  // Spread the rest of the props
    />
  );
};


export const countryCodes = [
  { code: "+1", label: ":us: USA" },
  { code: "+7", label: ":ru: Russia" },
  { code: "+20", label: ":flag-eg: Egypt" },
  { code: "+27", label: ":flag-za: South Africa" },
  { code: "+30", label: ":flag-gr: Greece" },
  { code: "+31", label: ":flag-nl: Netherlands" },
  { code: "+32", label: ":flag-be: Belgium" },
  { code: "+33", label: ":fr: France" },
  { code: "+34", label: ":es: Spain" },
  { code: "+36", label: ":flag-hu: Hungary" },
  { code: "+39", label: ":it: Italy" },
  { code: "+40", label: ":flag-ro: Romania" },
  { code: "+41", label: ":flag-ch: Switzerland" },
  { code: "+43", label: ":flag-at: Austria" },
  { code: "+44", label: ":gb: United Kingdom" },
  { code: "+45", label: ":flag-dk: Denmark" },
  { code: "+46", label: ":flag-se: Sweden" },
  { code: "+47", label: ":flag-no: Norway" },
  { code: "+48", label: ":flag-pl: Poland" },
  { code: "+49", label: ":de: Germany" },
  { code: "+51", label: ":flag-pe: Peru" },
  { code: "+52", label: ":flag-mx: Mexico" },
  { code: "+53", label: ":flag-cu: Cuba" },
  { code: "+54", label: ":flag-ar: Argentina" },
  { code: "+55", label: ":flag-br: Brazil" },
  { code: "+56", label: ":flag-cl: Chile" },
  { code: "+57", label: ":flag-co: Colombia" },
  { code: "+58", label: ":flag-ve: Venezuela" },
  { code: "+60", label: ":flag-my: Malaysia" },
  { code: "+61", label: ":flag-au: Australia" },
  { code: "+62", label: ":flag-id: Indonesia" },
  { code: "+63", label: ":flag-ph: Philippines" },
  { code: "+64", label: ":flag-nz: New Zealand" },
  { code: "+65", label: ":flag-sg: Singapore" },
  { code: "+66", label: ":flag-th: Thailand" },
  { code: "+81", label: ":jp: Japan" },
  { code: "+82", label: ":kr: South Korea" },
  { code: "+84", label: ":flag-vn: Vietnam" },
  { code: "+86", label: ":cn: China" },
  { code: "+90", label: ":flag-tr: Turkey" },
  { code: "+91", label: ":flag-in: India" },
  { code: "+92", label: ":flag-pk: Pakistan" },
  { code: "+93", label: ":flag-af: Afghanistan" },
  { code: "+94", label: ":flag-lk: Sri Lanka" },
  { code: "+95", label: ":flag-mm: Myanmar" },
  { code: "+98", label: ":flag-ir: Iran" },
  { code: "+211", label: ":flag-ss: South Sudan" },
  { code: "+212", label: ":flag-ma: Morocco" },
  { code: "+213", label: ":flag-dz: Algeria" },
  { code: "+216", label: ":flag-tn: Tunisia" },
  { code: "+218", label: ":flag-ly: Libya" },
  { code: "+220", label: ":flag-gm: Gambia" },
  { code: "+221", label: ":flag-sn: Senegal" },
  { code: "+222", label: ":flag-mr: Mauritania" },
  { code: "+223", label: ":flag-ml: Mali" },
  { code: "+224", label: ":flag-gn: Guinea" },
  { code: "+225", label: ":flag-ci: Ivory Coast" },
  { code: "+226", label: ":flag-bf: Burkina Faso" },
  { code: "+227", label: ":flag-ne: Niger" },
  { code: "+228", label: ":flag-tg: Togo" },
  { code: "+229", label: ":flag-bj: Benin" },
  { code: "+230", label: ":flag-mu: Mauritius" },
  { code: "+231", label: ":flag-lr: Liberia" },
  { code: "+232", label: ":flag-sl: Sierra Leone" },
  { code: "+233", label: ":flag-gh: Ghana" },
  { code: "+234", label: ":flag-ng: Nigeria" },
  { code: "+235", label: ":flag-td: Chad" },
  { code: "+236", label: ":flag-cf: Central African Republic" },
  { code: "+237", label: ":flag-cm: Cameroon" },
  { code: "+238", label: ":flag-cv: Cape Verde" },
  { code: "+239", label: ":flag-st: São Tomé and Príncipe" },
  { code: "+240", label: ":flag-gq: Equatorial Guinea" },
  { code: "+241", label: ":flag-ga: Gabon" },
  { code: "+242", label: ":flag-cg: Republic of the Congo" },
  { code: "+243", label: ":flag-cd: Democratic Republic of the Congo" },
  { code: "+244", label: ":flag-ao: Angola" },
  { code: "+245", label: ":flag-gw: Guinea-Bissau" },
  { code: "+246", label: ":flag-io: British Indian Ocean Territory" },
  { code: "+247", label: ":flag-ac: Ascension Island" },
  { code: "+248", label: ":flag-sc: Seychelles" },
  { code: "+249", label: ":flag-sd: Sudan" },
  { code: "+250", label: ":flag-rw: Rwanda" },
  { code: "+251", label: ":flag-et: Ethiopia" },
  { code: "+252", label: ":flag-so: Somalia" },
  { code: "+253", label: ":flag-dj: Djibouti" },
  { code: "+254", label: ":flag-ke: Kenya" },
  { code: "+255", label: ":flag-tz: Tanzania" },
  { code: "+256", label: ":flag-ug: Uganda" },
  { code: "+257", label: ":flag-bi: Burundi" },
  { code: "+258", label: ":flag-mz: Mozambique" },
  { code: "+260", label: ":flag-zm: Zambia" },
  { code: "+261", label: ":flag-mg: Madagascar" },
  { code: "+262", label: ":flag-re: Réunion" },
  { code: "+263", label: ":flag-zw: Zimbabwe" },
  { code: "+264", label: ":flag-na: Namibia" },
  { code: "+265", label: ":flag-mw: Malawi" },
  { code: "+266", label: ":flag-ls: Lesotho" },
  { code: "+267", label: ":flag-bw: Botswana" },
  { code: "+268", label: ":flag-sz: Eswatini" },
  { code: "+269", label: ":flag-km: Comoros" },
  { code: "+971", label: ":flag-ae: UAE" },
  { code: "+972", label: ":flag-il: Israel" },
  { code: "+973", label: ":flag-bh: Bahrain" },
  { code: "+974", label: ":flag-qa: Qatar" },
  { code: "+975", label: ":flag-bt: Bhutan" },
  { code: "+976", label: ":flag-mn: Mongolia" },
  { code: "+977", label: ":flag-np: Nepal" },
];


export const todayDate = new Date().toISOString().split('T')[0];

const today = new Date();
const oneYearAgo = new Date(today)

oneYearAgo.setFullYear(today.getFullYear() - 1);

// Format the date (you can adjust the format as needed)
export const lastYearDate = oneYearAgo.toISOString().split('T')[0];