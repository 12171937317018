import { Tooltip, tooltipClasses } from "@mui/material";
import styled from "@emotion/styled";

import HomeImage from "./SidebarIcons/Home.svg"
import DashboardImage from "./SidebarIcons/Dashboard.svg"


import MarketingImage from "./SidebarIcons/Marketing/Marketing.svg"
import LeadManagementImage from "./SidebarIcons/Marketing/LeadManagement.svg"
import LeadDashboardImage from "./SidebarIcons/Marketing/LeadDashboard.svg"
import LeadsImage from "./SidebarIcons/Marketing/Lead.svg"
import CallImage from "./SidebarIcons/Marketing/CallManagement.svg"
import ContactImage from "./SidebarIcons/Marketing/Contacts.svg"

import CustomerFeedbackImage from "./SidebarIcons/Marketing/CustomerFeedback.svg"
import FeedbackTemplateImage from "./SidebarIcons/Marketing/FeedbackTemplate.svg"
import FeedbackReportImage from "./SidebarIcons/Marketing/FeedbackReport.svg"
import FeedbackSummaryImage from "./SidebarIcons/Marketing/FeedbackSummary.svg"

import DealManagementImage from "./SidebarIcons/Marketing/DealManagement.svg"
import DealDashboardImage from "./SidebarIcons/Marketing/DealDashboard.svg"
import DealImage from "./SidebarIcons/Marketing/Deals.svg"
import PipelineDealImage from "./SidebarIcons/Marketing/PipelineDeals.svg"

import CampaignImage from "./SidebarIcons/Marketing/Campaign.svg"
import CampaignDashboardImage from "./SidebarIcons/Marketing/CampaignDashboard.svg"
import EmailImage from "./SidebarIcons/Marketing/Email.svg"
import CampaignTradeshowImage from "./SidebarIcons/Marketing/CampaignTradeshow.svg"
import SocialMediaCampaignImage from "./SidebarIcons/Marketing/SocialMediaCampaign.svg"


import SalesImage from "./SidebarIcons/Sales/Sales.svg"
import SalesDashboardImage from "./SidebarIcons/Sales/SalesDashboard.svg"
import SalesOrganizationImage from "./SidebarIcons/Sales/SalesOrganization.svg"
import SalesAccountImage from "./SidebarIcons/Sales/Accounts.svg"
import SalesBookingImage from "./SidebarIcons/Sales/Booking.svg"
import MembershipImage from "./SidebarIcons/Sales/Membership.svg"
import MembershipUsageImage from "./SidebarIcons/Sales/MembershipUsage.svg"
import SalesTargetImage from "./SidebarIcons/Sales/SalesTarget.svg"


import PropertyManagementImage from "./SidebarIcons/Property/PropertyManagement.svg"
import ProjectImage from "./SidebarIcons/Property/Project.svg"
import AgentImage from "./SidebarIcons/Property/Agent.svg"
import UnitMasterImage from "./SidebarIcons/Property/UnitMaster.svg"
import BlockImage from "./SidebarIcons/Property/Block.svg"
import ApartmentImage from "./SidebarIcons/Property/Apartment.svg"
import SiteVisitImage from "./SidebarIcons/Property/SiteVisit.svg"
import PlotImage from "./SidebarIcons/Property/Plot.svg"
import CancellationImage from "./SidebarIcons/Property/BookingCancellation.svg"
import ReservedImage from "./SidebarIcons/Property/Reserved.svg"
import FloorsImage from "./SidebarIcons/Property/Floor.svg"
import RoomsImage from "./SidebarIcons/Property/Room.svg"
import MeetingRoomImage from "./SidebarIcons/Property/MeetingRoom.svg"
import VisitorsImage from "./SidebarIcons/Property/Visitors.svg"


import ReportImage from "./SidebarIcons/Reports/Reports.svg"
import PropertyLeadReportImage from "./SidebarIcons/Reports/PropertyLeadReports.svg"
import LeadSourceReportImage from "./SidebarIcons/Reports/LeadSourceReports.svg"
import LeadConversionReportImage from "./SidebarIcons/Reports/LeadConversionReports.svg"
import LeadResponseTimeReportImage from "./SidebarIcons/Reports/LeadResponseTimeReport.svg"
import LeadActivityReportImage from "./SidebarIcons/Reports/LeadActivity.svg"
import LeadQualificationReportImage from "./SidebarIcons/Reports/LeadQualificationReport.svg"

import DealTransactionReportImage from "./SidebarIcons/Reports/DealTransactionReports.svg"
import DealPipelineReportImage from "./SidebarIcons/Reports/DealPipelineReports.svg"
import DealClosingReportImage from "./SidebarIcons/Reports/DealClosingReport.svg"
import LostDealReportImage from "./SidebarIcons/Reports/LostDealsReports.svg"
import DealValueReportImage from "./SidebarIcons/Reports/DealValueReport.svg"
import TimeToCloseReportImage from "./SidebarIcons/Reports/TimeToCloseReport.svg"

import PropertyReportImage from "./SidebarIcons/Reports/PropertyReports.svg"
import PropertyStatusReportImage from "./SidebarIcons/Reports/PropertyStatusReport.svg"
import PropertyEnquiryReportImage from "./SidebarIcons/Reports/PropertyEnquiryReports.svg"
import ListingActivityReportImage from "./SidebarIcons/Reports/ListingActivityReports.svg"

import AgentPerformanceReportImage from "./SidebarIcons/Reports/AgentTeamPerformanceReport.svg"
import AgentSalesReportImage from "./SidebarIcons/Reports/AgentSalesReports.svg"
import LeadResponseAgentReportImage from "./SidebarIcons/Reports/LeadResponseReportByAgent.svg"
import DealSourceReportImage from "./SidebarIcons/Reports/DealSourceReport.svg"

import ClientEngagementReportImage from "./SidebarIcons/Reports/ClientEngagementReports.svg"
import ClientCommunicationReportImage from "./SidebarIcons/Reports/ClientCommunication.svg"

import MarketingReportImage from "./SidebarIcons/Reports/MarketingReports.svg"
import LeadSourcePerformanceReportImage from "./SidebarIcons/Reports/LeadSourcePerformanceReport.svg"
import EmailCampaignReportImage from "./SidebarIcons/Reports/EmailCampaignReport.svg"


import AnalyticsImage from "./SidebarIcons/Analytics/Analytics.svg"
import SalesByCustomerImage from "./SidebarIcons/Analytics/SalesByCustomer.svg"
import ActivityOverviewImage from "./SidebarIcons/Analytics/AcitivityOverview.svg"
import SalesGoalsImage from "./SidebarIcons/Analytics/SalesGoals.svg"
import LeadSourceImage from "./SidebarIcons/Analytics/LeadSource.svg"
import CallDurationImage from "./SidebarIcons/Analytics/CallDuration.svg"
import CallLengthImage from "./SidebarIcons/Analytics/CallLength.svg"
import SalesOpportunitiesImage from "./SidebarIcons/Analytics/SalesOpportunitiesOverview.svg"
import OpportunitiesIndividualReportImage from "./SidebarIcons/Analytics/OpportunitiesIndividualReport.svg"
import ActivityIndividualReportImage from "./SidebarIcons/Analytics/AcivityIndividualReport.svg"
import CallActivityReportImage from "./SidebarIcons/Analytics/CallActivities.svg"


import SocialMediaEngagementImage from "./SidebarIcons/SocialMediaEngagement.svg"
import CustomFormImage from "./SidebarIcons/CustomForm.svg"


// green icons
import HomeImageG from "./SidebarIcons/HomeG.svg"
import DashboardImageG from "./SidebarIcons/DashboardG.svg"


import MarketingImageG from "./SidebarIcons/Marketing/MarketingG.svg"
import LeadManagementImageG from "./SidebarIcons/Marketing/LeadManagementG.svg"
import LeadDashboardImageG from "./SidebarIcons/Marketing/LeadDashboardG.svg"
import LeadsImageG from "./SidebarIcons/Marketing/LeadG.svg"
import CallImageG from "./SidebarIcons/Marketing/CallManagementG.svg"
import ContactImageG from "./SidebarIcons/Marketing/ContactsG.svg"

import CustomerFeedbackImageG from "./SidebarIcons/Marketing/CustomerFeedbackG.svg"
import FeedbackTemplateImageG from "./SidebarIcons/Marketing/FeedbackTemplateG.svg"
import FeedbackReportImageG from "./SidebarIcons/Marketing/FeedbackReportG.svg"
import FeedbackSummaryImageG from "./SidebarIcons/Marketing/FeedbackSummaryG.svg"

import DealManagementImageG from "./SidebarIcons/Marketing/DealManagementG.svg"
import DealDashboardImageG from "./SidebarIcons/Marketing/DealDashboardG.svg"
import DealImageG from "./SidebarIcons/Marketing/DealsG.svg"
import PipelineDealImageG from "./SidebarIcons/Marketing/PipelineDealsG.svg"

import CampaignImageG from "./SidebarIcons/Marketing/CampaignG.svg"
import CampaignDashboardImageG from "./SidebarIcons/Marketing/CampaignDashboardG.svg"
import EmailImageG from "./SidebarIcons/Marketing/EmailG.svg"
import CampaignTradeshowImageG from "./SidebarIcons/Marketing/CampaignTradeshowG.svg"
import SocialMediaCampaignImageG from "./SidebarIcons/Marketing/SocialMediaCampaignG.svg"


import SalesImageG from "./SidebarIcons/Sales/SalesG.svg"
import SalesDashboardImageG from "./SidebarIcons/Sales/SalesDashboardG.svg"
import SalesOrganizationImageG from "./SidebarIcons/Sales/SalesOrganizationG.svg"
import SalesAccountImageG from "./SidebarIcons/Sales/AccountsG.svg"
import SalesBookingImageG from "./SidebarIcons/Sales/BookingG.svg"
import MembershipImageG from "./SidebarIcons/Sales/MembershipG.svg"
import MembershipUsageImageG from "./SidebarIcons/Sales/MembershipUsageG.svg"
import SalesTargetImageG from "./SidebarIcons/Sales/SalesTargetG.svg"


import PropertyManagementImageG from "./SidebarIcons/Property/PropertyManagementG.svg"
import ProjectImageG from "./SidebarIcons/Property/ProjectG.svg"
import AgentImageG from "./SidebarIcons/Property/AgentG.svg"
import UnitMasterImageG from "./SidebarIcons/Property/UnitMasterG.svg"
import BlockImageG from "./SidebarIcons/Property/BlockG.svg"
import ApartmentImageG from "./SidebarIcons/Property/ApartmentG.svg"
import SiteVisitImageG from "./SidebarIcons/Property/SiteVisitG.svg"
import PlotImageG from "./SidebarIcons/Property/PlotG.svg"
import CancellationImageG from "./SidebarIcons/Property/BookingCancellationG.svg"
import ReservedImageG from "./SidebarIcons/Property/ReservedG.svg"
import FloorsImageG from "./SidebarIcons/Property/FloorG.svg"
import RoomsImageG from "./SidebarIcons/Property/RoomG.svg"
import MeetingRoomImageG from "./SidebarIcons/Property/MeetingRoomG.svg"
import VisitorsImageG from "./SidebarIcons/Property/VisitorsG.svg"


import ReportImageG from "./SidebarIcons/Reports/ReportsG.svg"
import PropertyLeadReportImageG from "./SidebarIcons/Reports/PropertyLeadReportsG.svg"
import LeadSourceReportImageG from "./SidebarIcons/Reports/LeadSourceReportsG.svg"
import LeadConversionReportImageG from "./SidebarIcons/Reports/LeadConversionReportsG.svg"
import LeadResponseTimeReportImageG from "./SidebarIcons/Reports/LeadResponseTimeReportG.svg"
import LeadActivityReportImageG from "./SidebarIcons/Reports/LeadActivityG.svg"
import LeadQualificationReportImageG from "./SidebarIcons/Reports/LeadQualificationReportG.svg"

import DealTransactionReportImageG from "./SidebarIcons/Reports/DealTransactionReportsG.svg"
import DealPipelineReportImageG from "./SidebarIcons/Reports/DealPipelineReportsG.svg"
import DealClosingReportImageG from "./SidebarIcons/Reports/DealClosingReportG.svg"
import LostDealReportImageG from "./SidebarIcons/Reports/LostDealsReportsG.svg"
import DealValueReportImageG from "./SidebarIcons/Reports/DealValueReportG.svg"
import TimeToCloseReportImageG from "./SidebarIcons/Reports/TimeToCloseReportG.svg"

import PropertyReportImageG from "./SidebarIcons/Reports/PropertyReportsG.svg"
import PropertyStatusReportImageG from "./SidebarIcons/Reports/PropertyStatusReportG.svg"
import PropertyEnquiryReportImageG from "./SidebarIcons/Reports/PropertyEnquiryReportsG.svg"
import ListingActivityReportImageG from "./SidebarIcons/Reports/ListingActivityReportsG.svg"

import AgentPerformanceReportImageG from "./SidebarIcons/Reports/AgentTeamPerformanceReportG.svg"
import AgentSalesReportImageG from "./SidebarIcons/Reports/AgentSalesReportsG.svg"
import LeadResponseAgentReportImageG from "./SidebarIcons/Reports/LeadResponseReportByAgentG.svg"
import DealSourceReportImageG from "./SidebarIcons/Reports/DealSourceReportG.svg"

import ClientEngagementReportImageG from "./SidebarIcons/Reports/ClientEngagementReportsG.svg"
import ClientCommunicationReportImageG from "./SidebarIcons/Reports/ClientCommunicationG.svg"

import MarketingReportImageG from "./SidebarIcons/Reports/MarketingReportsG.svg"
import LeadSourcePerformanceReportImageG from "./SidebarIcons/Reports/LeadSourcePerformanceReportG.svg"
import EmailCampaignReportImageG from "./SidebarIcons/Reports/EmailCampaignReportG.svg"


import AnalyticsImageG from "./SidebarIcons/Analytics/AnalyticsG.svg"
import SalesByCustomerImageG from "./SidebarIcons/Analytics/SalesByCustomerG.svg"
import ActivityOverviewImageG from "./SidebarIcons/Analytics/AcitivityOverviewG.svg"
import SalesGoalsImageG from "./SidebarIcons/Analytics/SalesGoalsG.svg"
import LeadSourceImageG from "./SidebarIcons/Analytics/LeadSourceG.svg"
import CallDurationImageG from "./SidebarIcons/Analytics/CallDurationG.svg"
import CallLengthImageG from "./SidebarIcons/Analytics/CallLengthG.svg"
import SalesOpportunitiesImageG from "./SidebarIcons/Analytics/SalesOpportunitiesOverviewG.svg"
import OpportunitiesIndividualReportImageG from "./SidebarIcons/Analytics/OpportunitiesIndividualReportG.svg"
import ActivityIndividualReportImageG from "./SidebarIcons/Analytics/AcivityIndividualReportG.svg"
import CallActivityReportImageG from "./SidebarIcons/Analytics/CallActivitiesG.svg"


import SocialMediaEngagementImageG from "./SidebarIcons/SocialMediaEngagementG.svg"
import CustomFormImageG from "./SidebarIcons/CustomFormG.svg"



const imageStyle = {
    width: 18,
    height: 18,
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 10,
    },
}));

export const MenuIcons = {
    HomeIcon: (
        <LightTooltip title="Home">
            <img src={HomeImage} alt="Home" style={imageStyle} />
        </LightTooltip>
    ),

    Dashboard: (
        <LightTooltip title="Dashboard">
            <img src={DashboardImage} alt="Dashboard" style={imageStyle} />
        </LightTooltip>
    ),

    MarketingIcon: (
        <LightTooltip title="Marketing">
            <img src={MarketingImage} alt="Marketing" style={imageStyle} />
        </LightTooltip>
    ),
    LeadManagementIcon: (
        <LightTooltip title="Lead Management">
            <img src={LeadManagementImage} alt="Lead Management" style={imageStyle} />
        </LightTooltip>
    ),
    LeadDashboardIcon: (
        <LightTooltip title="Lead Dashboard">
            <img src={LeadDashboardImage} alt="Lead Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    LeadsIcon: (
        <LightTooltip title="Leads">
            <img src={LeadsImage} alt="Leads" style={imageStyle} />
        </LightTooltip>
    ),
    CallManagementIcon: (
        <LightTooltip title="Call Management">
            <img src={CallImage} alt="Call Management" style={imageStyle} />
        </LightTooltip>
    ),
    ContactIcon: (
        <LightTooltip title="Contacts">
            <img src={ContactImage} alt="Contacts" style={imageStyle} />
        </LightTooltip>
    ),

    CustomerFeedbackIcon: (
        <LightTooltip title="Customer Feedback">
            <img src={CustomerFeedbackImage} alt="Customer Feedback" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackTemplateIcon: (
        <LightTooltip title="Feedback Template">
            <img src={FeedbackTemplateImage} alt="Feedback Template" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackReportIcon: (
        <LightTooltip title="Feedback Report">
            <img src={FeedbackReportImage} alt="Feedback Report" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackSummaryIcon: (
        <LightTooltip title="Feedback Summary">
            <img src={FeedbackSummaryImage} alt="Feedback Summary" style={imageStyle} />
        </LightTooltip>
    ),

    DealManagementIcon: (
        <LightTooltip title="Deal Management">
            <img src={DealManagementImage} alt="Deal Management" style={imageStyle} />
        </LightTooltip>
    ),
    DealDashboardIcon: (
        <LightTooltip title="Deal Dashboard">
            <img src={DealDashboardImage} alt="Deal Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    DealIcon: (
        <LightTooltip title="Deal">
            <img src={DealImage} alt="Deal" style={imageStyle} />
        </LightTooltip>
    ),
    PipelineDealIcon: (
        <LightTooltip title="Pipeline Deals">
            <img src={PipelineDealImage} alt="Pipeline Deals" style={imageStyle} />
        </LightTooltip>
    ),

    CampaignIcon: (
        <LightTooltip title="Campaign Management">
            <img src={CampaignImage} alt="Campaign Management" style={imageStyle} />
        </LightTooltip>
    ),
    CampaignDashboardIcon: (
        <LightTooltip title="Campaign Dashboard">
            <img src={CampaignDashboardImage} alt="Campaign Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    EmailIcon: (
        <LightTooltip title="Email">
            <img src={EmailImage} alt="Email" style={imageStyle} />
        </LightTooltip>
    ),
    CampaignTradeshowIcon: (
        <LightTooltip title="Campaign Tradeshow">
            <img src={CampaignTradeshowImage} alt="Campaign Tradeshow" style={imageStyle} />
        </LightTooltip>
    ),
    SocialMediaCampaignIcon: (
        <LightTooltip title="SocialMedia Campaign">
            <img src={SocialMediaCampaignImage} alt="SocialMedia Campaign" style={imageStyle} />
        </LightTooltip>
    ),


    SalesIcon: (
        <LightTooltip title="Sales">
            <img src={SalesImage} alt="Sales" style={imageStyle} />
        </LightTooltip>
    ),
    SalesDashboardIcon: (
        <LightTooltip title="Sales Dashboard">
            <img src={SalesDashboardImage} alt="Sales Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    SalesOrganizationIcon: (
        <LightTooltip title="Sales Organization">
            <img src={SalesOrganizationImage} alt="Sales Organization" style={imageStyle} />
        </LightTooltip>
    ),
    SalesAccountIcon: (
        <LightTooltip title="Account">
            <img src={SalesAccountImage} alt="Account" style={imageStyle} />
        </LightTooltip>
    ),
    SalesBookingIcon: (
        <LightTooltip title="Booking">
            <img src={SalesBookingImage} alt="Booking" style={imageStyle} />
        </LightTooltip>
    ),
    MembershipIcon: (
        <LightTooltip title="Membership">
            <img src={MembershipImage} alt="Membership" style={imageStyle} />
        </LightTooltip>
    ),
    MembershipUsageIcon: (
        <LightTooltip title="Membership Usage">
            <img src={MembershipUsageImage} alt="Membership Usage" style={imageStyle} />
        </LightTooltip>
    ),
    SalesTargetIcon: (
        <LightTooltip title="Sales Target">
            <img src={SalesTargetImage} alt="Sales Target" style={imageStyle} />
        </LightTooltip>
    ),


    PropertyManagementIcon: (
        <LightTooltip title="Property Management">
            <img src={PropertyManagementImage} alt="Property Management" style={imageStyle} />
        </LightTooltip>
    ),
    ProjectIcon: (
        <LightTooltip title="Project">
            <img src={ProjectImage} alt="Project" style={imageStyle} />
        </LightTooltip>
    ),
    AgentIcon: (
        <LightTooltip title="Agent">
            <img src={AgentImage} alt="Agent" style={imageStyle} />
        </LightTooltip>
    ),
    UnitMasterIcon: (
        <LightTooltip title="Unit Master">
            <img src={UnitMasterImage} alt="Unit Master" style={imageStyle} />
        </LightTooltip>
    ),
    BlockIcon: (
        <LightTooltip title="Block">
            <img src={BlockImage} alt="Block" style={imageStyle} />
        </LightTooltip>
    ),
    ApartmentIcon: (
        <LightTooltip title="Apartment">
            <img src={ApartmentImage} alt="Apartment" style={imageStyle} />
        </LightTooltip>
    ),
    SiteVisitIcon: (
        <LightTooltip title="Site Visit">
            <img src={SiteVisitImage} alt="Site Visit" style={imageStyle} />
        </LightTooltip>
    ),
    PlotIcon: (
        <LightTooltip title="Plot">
            <img src={PlotImage} alt="Plot" style={imageStyle} />
        </LightTooltip>
    ),
    CancellationIcon: (
        <LightTooltip title="Booking Cancellation">
            <img src={CancellationImage} alt="Booking Cancellation" style={imageStyle} />
        </LightTooltip>
    ),
    ReservedIcon: (
        <LightTooltip title="Reserved">
            <img src={ReservedImage} alt="Reserved" style={imageStyle} />
        </LightTooltip>
    ),
    FloorsIcon: (
        <LightTooltip title="Floors">
            <img src={FloorsImage} alt="Floors" style={imageStyle} />
        </LightTooltip>
    ),
    RoomsIcon: (
        <LightTooltip title="Rooms">
            <img src={RoomsImage} alt="Rooms" style={imageStyle} />
        </LightTooltip>
    ),
    MeetingRoomIcon: (
        <LightTooltip title="Meeting Room">
            <img src={MeetingRoomImage} alt="Meeting Room" style={imageStyle} />
        </LightTooltip>
    ),
    VisitorsIcon: (
        <LightTooltip title="Visitor Management">
            <img src={VisitorsImage} alt="Visitor Management" style={imageStyle} />
        </LightTooltip>
    ),


    ReportIcon: (
        <LightTooltip title="Reports">
            <img src={ReportImage} alt="Reports" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyLeadReportIcon: (
        <LightTooltip title="Property Lead Report">
            <img src={PropertyLeadReportImage} alt="Property Lead Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourceReportIcon: (
        <LightTooltip title="Lead Source Report">
            <img src={LeadSourceReportImage} alt="Lead Source Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadConversionReportIcon: (
        <LightTooltip title="Lead Conversion Report">
            <img src={LeadConversionReportImage} alt="Lead Conversion Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadResponseTimeReportIcon: (
        <LightTooltip title="Lead Response Time Report">
            <img src={LeadResponseTimeReportImage} alt="Lead Response Time Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadActivityReportIcon: (
        <LightTooltip title="Lead Activity Report">
            <img src={LeadActivityReportImage} alt="Lead Activity Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadQualificationReportIcon: (
        <LightTooltip title="Lead Qualification Report">
            <img src={LeadQualificationReportImage} alt="Lead Qualification Report" style={imageStyle} />
        </LightTooltip>
    ),

    DealReportIcon: (
        <LightTooltip title="Deal/Transaction Report">
            <img src={DealTransactionReportImage} alt="Deal/Transaction Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealPipelineReportIcon: (
        <LightTooltip title="Deal Pipeline Report">
            <img src={DealPipelineReportImage} alt="Deal Pipeline Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealClosingReportIcon: (
        <LightTooltip title="Deal Closing Report">
            <img src={DealClosingReportImage} alt="Deal Closing Report" style={imageStyle} />
        </LightTooltip>
    ),
    LostDealReportIcon: (
        <LightTooltip title="Lost Deals Report">
            <img src={LostDealReportImage} alt="Lost Deals Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealValueReportIcon: (
        <LightTooltip title="Deal Value Report">
            <img src={DealValueReportImage} alt="Deal Value Report" style={imageStyle} />
        </LightTooltip>
    ),
    TimeToCloseReportIcon: (
        <LightTooltip title="Time-to-Close Report">
            <img src={TimeToCloseReportImage} alt="Time-to-Close Report" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyReportIcon: (
        <LightTooltip title="Property Reports">
            <img src={PropertyReportImage} alt="Property Reports" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyStatusReportIcon: (
        <LightTooltip title="Property Status Report">
            <img src={PropertyStatusReportImage} alt="Property Status Report" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyEnquiryReportIcon: (
        <LightTooltip title="Property Enquiry Report">
            <img src={PropertyEnquiryReportImage} alt="Property Enquiry Report" style={imageStyle} />
        </LightTooltip>
    ),
    ListingActivityReportIcon: (
        <LightTooltip title="Listing Activity Report">
            <img src={ListingActivityReportImage} alt="Listing Activity Report" style={imageStyle} />
        </LightTooltip>
    ),

    AgentPerformanceReportIcon: (
        <LightTooltip title="Agent/Team Performance Reports">
            <img src={AgentPerformanceReportImage} alt="Agent/Team Performance Reports" style={imageStyle} />
        </LightTooltip>
    ),
    AgentSalesReportIcon: (
        <LightTooltip title="Agent Sales Report">
            <img src={AgentSalesReportImage} alt="Agent Sales Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadResponseAgentReportIcon: (
        <LightTooltip title="Lead Response Report by Agent">
            <img src={LeadResponseAgentReportImage} alt="Lead Response Report by Agent" style={imageStyle} />
        </LightTooltip>
    ),
    DealSourceReportIcon: (
        <LightTooltip title="Deal Source Report">
            <img src={DealSourceReportImage} alt="Deal Source Report" style={imageStyle} />
        </LightTooltip>
    ),

    ClientEngagementReportIcon: (
        <LightTooltip title="Client Engagement Reports">
            <img src={ClientEngagementReportImage} alt="Client Engagement Reports" style={imageStyle} />
        </LightTooltip>
    ),
    ClientCommunicationReportIcon: (
        <LightTooltip title="Client Communication Report">
            <img src={ClientCommunicationReportImage} alt="Client Communication Report" style={imageStyle} />
        </LightTooltip>
    ),

    MarketingReportIcon: (
        <LightTooltip title="Marketing Reports">
            <img src={MarketingReportImage} alt="Marketing Reports" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourcePerformanceReportIcon: (
        <LightTooltip title="Lead Source Performance Report">
            <img src={LeadSourcePerformanceReportImage} alt="Lead Source Performance Report" style={imageStyle} />
        </LightTooltip>
    ),
    EmailCampaignReportIcon: (
        <LightTooltip title="Email Campaign Report">
            <img src={EmailCampaignReportImage} alt="Email Campaign Report" style={imageStyle} />
        </LightTooltip>
    ),


    AnalyticsIcon: (
        <LightTooltip title="Analytics">
            <img src={AnalyticsImage} alt="Analytics" style={imageStyle} />
        </LightTooltip>
    ),
    SalesByCustomerIcon: (
        <LightTooltip title="Sales by Customer">
            <img src={SalesByCustomerImage} alt="Sales by Customer" style={imageStyle} />
        </LightTooltip>
    ),
    ActivityOverviewIcon: (
        <LightTooltip title="Activity Overview">
            <img src={ActivityOverviewImage} alt="Activity Overview" style={imageStyle} />
        </LightTooltip>
    ),
    SalesGoalsIcon: (
        <LightTooltip title="Sales Goals">
            <img src={SalesGoalsImage} alt="Sales Goals" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourceIcon: (
        <LightTooltip title="Lead Sources">
            <img src={LeadSourceImage} alt="Lead Sources" style={imageStyle} />
        </LightTooltip>
    ),
    CallDurationIcon: (
        <LightTooltip title="Call Duration">
            <img src={CallDurationImage} alt="Call Duration" style={imageStyle} />
        </LightTooltip>
    ),
    CallLengthIcon: (
        <LightTooltip title="Call Length Vs Time Of Day">
            <img src={CallLengthImage} alt="Call Length Vs Time Of Day" style={imageStyle} />
        </LightTooltip>
    ),
    SalesOpportunitiesIcon: (
        <LightTooltip title="Sales Opportunities Overview">
            <img src={SalesOpportunitiesImage} alt="Sales Opportunities Overview" style={imageStyle} />
        </LightTooltip>
    ),
    OpportunitiesIndividualReportIcon: (
        <LightTooltip title="Opportunities Individual Report">
            <img src={OpportunitiesIndividualReportImage} alt="Opportunities Individual Report" style={imageStyle} />
        </LightTooltip>
    ),
    ActivityIndividualReportIcon: (
        <LightTooltip title="Activity Individual Report">
            <img src={ActivityIndividualReportImage} alt="Activity Individual Report" style={imageStyle} />
        </LightTooltip>
    ),
    CallActivityReportIcon: (
        <LightTooltip title="Call Activities Report">
            <img src={CallActivityReportImage} alt="Call Activities Report" style={imageStyle} />
        </LightTooltip>
    ),


    SocialMediaEngagementIcon: (
        <LightTooltip title="SocialMedia Engagement">
            <img src={SocialMediaEngagementImage} alt="SocialMedia Engagement" style={imageStyle} />
        </LightTooltip>
    ),


    CustomFormIcon: (
        <LightTooltip title="Custom Form">
            <img src={CustomFormImage} alt="Custom Form" style={imageStyle} />
        </LightTooltip>
    ),
};


export const MenuIconsG = {
    HomeIcon: (
        <LightTooltip title="Home">
            <img src={HomeImageG} alt="Home" style={imageStyle} />
        </LightTooltip>
    ),

    Dashboard: (
        <LightTooltip title="Dashboard">
            <img src={DashboardImageG} alt="Dashboard" style={imageStyle} />
        </LightTooltip>
    ),

    MarketingIcon: (
        <LightTooltip title="Marketing">
            <img src={MarketingImageG} alt="Marketing" style={imageStyle} />
        </LightTooltip>
    ),
    LeadManagementIcon: (
        <LightTooltip title="Lead Management">
            <img src={LeadManagementImageG} alt="Lead Management" style={imageStyle} />
        </LightTooltip>
    ),
    LeadDashboardIcon: (
        <LightTooltip title="Lead Dashboard">
            <img src={LeadDashboardImageG} alt="Lead Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    LeadsIcon: (
        <LightTooltip title="Leads">
            <img src={LeadsImageG} alt="Leads" style={imageStyle} />
        </LightTooltip>
    ),
    CallManagementIcon: (
        <LightTooltip title="Call Management">
            <img src={CallImageG} alt="Call Management" style={imageStyle} />
        </LightTooltip>
    ),
    ContactIcon: (
        <LightTooltip title="Contacts">
            <img src={ContactImageG} alt="Contacts" style={imageStyle} />
        </LightTooltip>
    ),

    CustomerFeedbackIcon: (
        <LightTooltip title="Customer Feedback">
            <img src={CustomerFeedbackImageG} alt="Customer Feedback" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackTemplateIcon: (
        <LightTooltip title="Feedback Template">
            <img src={FeedbackTemplateImageG} alt="Feedback Template" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackReportIcon: (
        <LightTooltip title="Feedback Report">
            <img src={FeedbackReportImageG} alt="Feedback Report" style={imageStyle} />
        </LightTooltip>
    ),
    FeedbackSummaryIcon: (
        <LightTooltip title="Feedback Summary">
            <img src={FeedbackSummaryImageG} alt="Feedback Summary" style={imageStyle} />
        </LightTooltip>
    ),

    DealManagementIcon: (
        <LightTooltip title="Deal Management">
            <img src={DealManagementImageG} alt="Deal Management" style={imageStyle} />
        </LightTooltip>
    ),
    DealDashboardIcon: (
        <LightTooltip title="Deal Dashboard">
            <img src={DealDashboardImageG} alt="Deal Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    DealIcon: (
        <LightTooltip title="Deal">
            <img src={DealImageG} alt="Deal" style={imageStyle} />
        </LightTooltip>
    ),
    PipelineDealIcon: (
        <LightTooltip title="Pipeline Deals">
            <img src={PipelineDealImageG} alt="Pipeline Deals" style={imageStyle} />
        </LightTooltip>
    ),

    CampaignIcon: (
        <LightTooltip title="Campaign Management">
            <img src={CampaignImageG} alt="Campaign Management" style={imageStyle} />
        </LightTooltip>
    ),
    CampaignDashboardIcon: (
        <LightTooltip title="Campaign Dashboard">
            <img src={CampaignDashboardImageG} alt="Campaign Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    EmailIcon: (
        <LightTooltip title="Email">
            <img src={EmailImageG} alt="Email" style={imageStyle} />
        </LightTooltip>
    ),
    CampaignTradeshowIcon: (
        <LightTooltip title="Campaign Tradeshow">
            <img src={CampaignTradeshowImageG} alt="Campaign Tradeshow" style={imageStyle} />
        </LightTooltip>
    ),
    SocialMediaCampaignIcon: (
        <LightTooltip title="SocialMedia Campaign">
            <img src={SocialMediaCampaignImageG} alt="SocialMedia Campaign" style={imageStyle} />
        </LightTooltip>
    ),


    SalesIcon: (
        <LightTooltip title="Sales">
            <img src={SalesImageG} alt="Sales" style={imageStyle} />
        </LightTooltip>
    ),
    SalesDashboardIcon: (
        <LightTooltip title="Sales Dashboard">
            <img src={SalesDashboardImageG} alt="Sales Dashboard" style={imageStyle} />
        </LightTooltip>
    ),
    SalesOrganizationIcon: (
        <LightTooltip title="Sales Organization">
            <img src={SalesOrganizationImageG} alt="Sales Organization" style={imageStyle} />
        </LightTooltip>
    ),
    SalesAccountIcon: (
        <LightTooltip title="Account">
            <img src={SalesAccountImageG} alt="Account" style={imageStyle} />
        </LightTooltip>
    ),
    SalesBookingIcon: (
        <LightTooltip title="Booking">
            <img src={SalesBookingImageG} alt="Booking" style={imageStyle} />
        </LightTooltip>
    ),
    MembershipIcon: (
        <LightTooltip title="Membership">
            <img src={MembershipImageG} alt="Membership" style={imageStyle} />
        </LightTooltip>
    ),
    MembershipUsageIcon: (
        <LightTooltip title="Membership Usage">
            <img src={MembershipUsageImageG} alt="Membership Usage" style={imageStyle} />
        </LightTooltip>
    ),
    SalesTargetIcon: (
        <LightTooltip title="Sales Target">
            <img src={SalesTargetImageG} alt="Sales Target" style={imageStyle} />
        </LightTooltip>
    ),


    PropertyManagementIcon: (
        <LightTooltip title="Property Management">
            <img src={PropertyManagementImageG} alt="Property Management" style={imageStyle} />
        </LightTooltip>
    ),
    ProjectIcon: (
        <LightTooltip title="Project">
            <img src={ProjectImageG} alt="Project" style={imageStyle} />
        </LightTooltip>
    ),
    AgentIcon: (
        <LightTooltip title="Agent">
            <img src={AgentImageG} alt="Agent" style={imageStyle} />
        </LightTooltip>
    ),
    UnitMasterIcon: (
        <LightTooltip title="Unit Master">
            <img src={UnitMasterImageG} alt="Unit Master" style={imageStyle} />
        </LightTooltip>
    ),
    BlockIcon: (
        <LightTooltip title="Block">
            <img src={BlockImageG} alt="Block" style={imageStyle} />
        </LightTooltip>
    ),
    ApartmentIcon: (
        <LightTooltip title="Apartment">
            <img src={ApartmentImageG} alt="Apartment" style={imageStyle} />
        </LightTooltip>
    ),
    SiteVisitIcon: (
        <LightTooltip title="Site Visit">
            <img src={SiteVisitImageG} alt="Site Visit" style={imageStyle} />
        </LightTooltip>
    ),
    PlotIcon: (
        <LightTooltip title="Plot">
            <img src={PlotImageG} alt="Plot" style={imageStyle} />
        </LightTooltip>
    ),
    CancellationIcon: (
        <LightTooltip title="Booking Cancellation">
            <img src={CancellationImageG} alt="Booking Cancellation" style={imageStyle} />
        </LightTooltip>
    ),
    ReservedIcon: (
        <LightTooltip title="Reserved">
            <img src={ReservedImageG} alt="Reserved" style={imageStyle} />
        </LightTooltip>
    ),
    FloorsIcon: (
        <LightTooltip title="Floors">
            <img src={FloorsImageG} alt="Floors" style={imageStyle} />
        </LightTooltip>
    ),
    RoomsIcon: (
        <LightTooltip title="Rooms">
            <img src={RoomsImageG} alt="Rooms" style={imageStyle} />
        </LightTooltip>
    ),
    MeetingRoomIcon: (
        <LightTooltip title="Meeting Room">
            <img src={MeetingRoomImageG} alt="Meeting Room" style={imageStyle} />
        </LightTooltip>
    ),
    VisitorsIcon: (
        <LightTooltip title="Visitor Management">
            <img src={VisitorsImageG} alt="Visitor Management" style={imageStyle} />
        </LightTooltip>
    ),


    ReportIcon: (
        <LightTooltip title="Reports">
            <img src={ReportImageG} alt="Reports" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyLeadReportIcon: (
        <LightTooltip title="Property Lead Report">
            <img src={PropertyLeadReportImageG} alt="Property Lead Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourceReportIcon: (
        <LightTooltip title="Lead Source Report">
            <img src={LeadSourceReportImageG} alt="Lead Source Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadConversionReportIcon: (
        <LightTooltip title="Lead Conversion Report">
            <img src={LeadConversionReportImageG} alt="Lead Conversion Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadResponseTimeReportIcon: (
        <LightTooltip title="Lead Response Time Report">
            <img src={LeadResponseTimeReportImageG} alt="Lead Response Time Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadActivityReportIcon: (
        <LightTooltip title="Lead Activity Report">
            <img src={LeadActivityReportImageG} alt="Lead Activity Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadQualificationReportIcon: (
        <LightTooltip title="Lead Qualification Report">
            <img src={LeadQualificationReportImageG} alt="Lead Qualification Report" style={imageStyle} />
        </LightTooltip>
    ),

    DealReportIcon: (
        <LightTooltip title="Deal/Transaction Report">
            <img src={DealTransactionReportImageG} alt="Deal/Transaction Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealPipelineReportIcon: (
        <LightTooltip title="Deal Pipeline Report">
            <img src={DealPipelineReportImageG} alt="Deal Pipeline Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealClosingReportIcon: (
        <LightTooltip title="Deal Closing Report">
            <img src={DealClosingReportImageG} alt="Deal Closing Report" style={imageStyle} />
        </LightTooltip>
    ),
    LostDealReportIcon: (
        <LightTooltip title="Lost Deals Report">
            <img src={LostDealReportImageG} alt="Lost Deals Report" style={imageStyle} />
        </LightTooltip>
    ),
    DealValueReportIcon: (
        <LightTooltip title="Deal Value Report">
            <img src={DealValueReportImageG} alt="Deal Value Report" style={imageStyle} />
        </LightTooltip>
    ),
    TimeToCloseReportIcon: (
        <LightTooltip title="Time-to-Close Report">
            <img src={TimeToCloseReportImageG} alt="Time-to-Close Report" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyReportIcon: (
        <LightTooltip title="Property Reports">
            <img src={PropertyReportImageG} alt="Property Reports" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyStatusReportIcon: (
        <LightTooltip title="Property Status Report">
            <img src={PropertyStatusReportImageG} alt="Property Status Report" style={imageStyle} />
        </LightTooltip>
    ),
    PropertyEnquiryReportIcon: (
        <LightTooltip title="Property Enquiry Report">
            <img src={PropertyEnquiryReportImageG} alt="Property Enquiry Report" style={imageStyle} />
        </LightTooltip>
    ),
    ListingActivityReportIcon: (
        <LightTooltip title="Listing Activity Report">
            <img src={ListingActivityReportImageG} alt="Listing Activity Report" style={imageStyle} />
        </LightTooltip>
    ),

    AgentPerformanceReportIcon: (
        <LightTooltip title="Agent/Team Performance Reports">
            <img src={AgentPerformanceReportImageG} alt="Agent/Team Performance Reports" style={imageStyle} />
        </LightTooltip>
    ),
    AgentSalesReportIcon: (
        <LightTooltip title="Agent Sales Report">
            <img src={AgentSalesReportImageG} alt="Agent Sales Report" style={imageStyle} />
        </LightTooltip>
    ),
    LeadResponseAgentReportIcon: (
        <LightTooltip title="Lead Response Report by Agent">
            <img src={LeadResponseAgentReportImageG} alt="Lead Response Report by Agent" style={imageStyle} />
        </LightTooltip>
    ),
    DealSourceReportIcon: (
        <LightTooltip title="Deal Source Report">
            <img src={DealSourceReportImageG} alt="Deal Source Report" style={imageStyle} />
        </LightTooltip>
    ),

    ClientEngagementReportIcon: (
        <LightTooltip title="Client Engagement Reports">
            <img src={ClientEngagementReportImageG} alt="Client Engagement Reports" style={imageStyle} />
        </LightTooltip>
    ),
    ClientCommunicationReportIcon: (
        <LightTooltip title="Client Communication Report">
            <img src={ClientCommunicationReportImageG} alt="Client Communication Report" style={imageStyle} />
        </LightTooltip>
    ),

    MarketingReportIcon: (
        <LightTooltip title="Marketing Reports">
            <img src={MarketingReportImageG} alt="Marketing Reports" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourcePerformanceReportIcon: (
        <LightTooltip title="Lead Source Performance Report">
            <img src={LeadSourcePerformanceReportImageG} alt="Lead Source Performance Report" style={imageStyle} />
        </LightTooltip>
    ),
    EmailCampaignReportIcon: (
        <LightTooltip title="Email Campaign Report">
            <img src={EmailCampaignReportImageG} alt="Email Campaign Report" style={imageStyle} />
        </LightTooltip>
    ),


    AnalyticsIcon: (
        <LightTooltip title="Analytics">
            <img src={AnalyticsImageG} alt="Analytics" style={imageStyle} />
        </LightTooltip>
    ),
    SalesByCustomerIcon: (
        <LightTooltip title="Sales by Customer">
            <img src={SalesByCustomerImageG} alt="Sales by Customer" style={imageStyle} />
        </LightTooltip>
    ),
    ActivityOverviewIcon: (
        <LightTooltip title="Activity Overview">
            <img src={ActivityOverviewImageG} alt="Activity Overview" style={imageStyle} />
        </LightTooltip>
    ),
    SalesGoalsIcon: (
        <LightTooltip title="Sales Goals">
            <img src={SalesGoalsImageG} alt="Sales Goals" style={imageStyle} />
        </LightTooltip>
    ),
    LeadSourceIcon: (
        <LightTooltip title="Lead Sources">
            <img src={LeadSourceImageG} alt="Lead Sources" style={imageStyle} />
        </LightTooltip>
    ),
    CallDurationIcon: (
        <LightTooltip title="Call Duration">
            <img src={CallDurationImageG} alt="Call Duration" style={imageStyle} />
        </LightTooltip>
    ),
    CallLengthIcon: (
        <LightTooltip title="Call Length Vs Time Of Day">
            <img src={CallLengthImageG} alt="Call Length Vs Time Of Day" style={imageStyle} />
        </LightTooltip>
    ),
    SalesOpportunitiesIcon: (
        <LightTooltip title="Sales Opportunities Overview">
            <img src={SalesOpportunitiesImageG} alt="Sales Opportunities Overview" style={imageStyle} />
        </LightTooltip>
    ),
    OpportunitiesIndividualReportIcon: (
        <LightTooltip title="Opportunities Individual Report">
            <img src={OpportunitiesIndividualReportImageG} alt="Opportunities Individual Report" style={imageStyle} />
        </LightTooltip>
    ),
    ActivityIndividualReportIcon: (
        <LightTooltip title="Activity Individual Report">
            <img src={ActivityIndividualReportImageG} alt="Activity Individual Report" style={imageStyle} />
        </LightTooltip>
    ),
    CallActivityReportIcon: (
        <LightTooltip title="Call Activities Report">
            <img src={CallActivityReportImageG} alt="Call Activities Report" style={imageStyle} />
        </LightTooltip>
    ),


    SocialMediaEngagementIcon: (
        <LightTooltip title="SocialMedia Engagement">
            <img src={SocialMediaEngagementImageG} alt="SocialMedia Engagement" style={imageStyle} />
        </LightTooltip>
    ),


    CustomFormIcon: (
        <LightTooltip title="Custom Form">
            <img src={CustomFormImageG} alt="Custom Form" style={imageStyle} />
        </LightTooltip>
    ),
};