import MarketingImg from "../../assets/images/landing-Images/marketing.png";
import SalesImg from "../../assets/images/landing-Images/sales.png";
import ContactImg from "../../assets/images/landing-Images/contact.png";
import LeadImg from "../../assets/images/landing-Images/lead.png";
import DealImg from "../../assets/images/landing-Images/deal-management.png";
import CampaignImg from "../../assets/images/landing-Images/Campaign.png";
import AccountImg from "../../assets/images/landing-Images/accounts.jpg";
import FeedbackImg from "../../assets/images/landing-Images/feedback.jpg";
import ConfigProductImg from "../../assets/images/landing-Images/Confi-product.jpg";
import StoreImg from "../../assets/images/landing-Images/store.png";
import TerritoryImg from "../../assets/images/landing-Images/territory.png";
import ProjectImg from "../../assets/images/landing-Images/project.png";
import TaskManagementImg from "../../assets/images/landing-Images/taskmanagement.png";
import WorkflowImg from "../../assets/images/landing-Images/workflow.png";
import ReportImg from "../../assets/images/landing-Images/report.png";
import SalesPerformanceImg from "../../assets/images/landing-Images/sales-performances.png";
import LeadOpportunityImg from "../../assets/images/landing-Images/lead-opp.png";
import CustomerEngageImg from "../../assets/images/landing-Images/customer_engaegment.jpg";
import MarkettingEffectiveImg from "../../assets/images/landing-Images/marketing-effectiveness.png";
import CustomerRetentionImg from "../../assets/images/landing-Images/customer-retention.jpg";
import GeneralReportImg from "../../assets/images/landing-Images/general-report.png";
import SalesFunnelImg from "../../assets/images/landing-Images/sales-funnel.png";
import AnalyticsImg from "../../assets/images/landing-Images/analytics.jpg";
import CustomFormImg from "../../assets/images/landing-Images/Custome-form.jpg";
import CustomerEngagementImg from "../../assets/images/landing-Images/customer_engaegment.jpg";

export const landingDatas = [
  {
    id: "marketing",
    title: "Marketing",
    image: MarketingImg,
    content: {
      subContent:
        "The aim of marketing is to know and understand the customer so well the product or service fits him and sells itself.",
      author: "Peter Drucker",
      para: "One place to manage your contacts, leads, deals and campaigns.",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Seamless Integration with Sales: </span>
          {" "}CRM FARM’s marketing module is seamlessly integrated with the sales pipeline.
        </>,
        "Facilitates a seamless journey from lead generation to conversion.",
        "Ensuring that sales teams have access to all relevant marketing data, improving collaboration and overall sales effectiveness.",
      ],
    },
  },
  {
    id: "contactmanagement",
    title: "Contact Management",
    image: ContactImg,
    content: {
      subContent:
        "The richest people in the world look for and build networks; everyone else looks for work.",
      author: "Robert Kiyosaki",
      para: "One place to manage your contacts and calls.",
      points: [
        "Centralized Contact Repository provides a single, unified platform where all your customer and prospect information is stored.",
        "Ensures that your team has easy access to up-to-date contact details, interaction history, and communication preferences, enhancing collaboration and customer service.",
        "Gain a comprehensive view of every interaction your business has with a contact, from emails and phone calls to meetings and social media engagements.",
      ],
    },
  },
  {
    id: "leadmanagement",
    title: "Lead Management",
    image: LeadImg,
    content: {
      subContent:
        "Nurturing your leads with the right content at the right time is the cornerstone of successful lead management",
      author: "Brian Halligan",
      para: "Lead Management made easy",
      points: [
        "Complete lead journey as per your convenience, lead management made easy & simplified.",
        "With CRM FARM, you can monitor the progress of every lead in real-time, from initial contact to closing the deal.",
        "The platform offers detailed analytics and reporting, enabling data-driven decisions to optimize your lead management strategies.",
      ],
    },
  },
  {
    id: "dealmanagement",
    title: "Deal Management",
    image: DealImg,
    content: {
      subContent:
        "The best way to close a deal is to provide value. If you can create value, people will pay for it.",
      author: "Gary Vaynerchuk",
      para: "Manage your deals, dashboard and pipeline deals.",
      points: [
        "Create and customize deal pipelines to match your sales process",
        "Flexibility to track deals at every stage, ensuring that no opportunity is overlooked and that your sales process aligns perfectly with your business needs.",
        "Helps in better decision-making to identify trends, predict outcomes, and adjust strategies to close more deals successfully.",
      ],
    },
  },
  {
    id: "campaign",
    title: "Campaign",
    image: CampaignImg,
    content: {
      subContent:
        "A successful campaign requires both a compelling message and the discipline to stay on that message.",
      author: "Karl Rove",
      para: "Complete control over campaigning",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Targeted Campaign Management:</span>
          {" "}CRM FARM allows you to segment your customer base and create highly targeted marketing campaigns.
        </>,
        "Ensures that the right message reaches the right audience, increasing the effectiveness of your marketing efforts and boosting conversion rates.",
      ],
    },
  },
  {
    id: "sales",
    title: "Sales",
    image: SalesImg,
    content: {
      subContent: "Great salespeople are relationship builders who provide value and help their customers win.",
      author: "Jeffrey Gitomer",
      para: "sales content",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Sales Management:</span>
          {" "}CRM FARM’s Sales module provides an all-in-one platform to manage every aspect of the sales process—from customer enquiries and quotations to order management, invoicing, and payments—ensuring nothing falls through the cracks.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Streamlined Workflow with Real-Time Insights:</span>
          {" "}The intuitive dashboard offers real-time insights into sales performance, while integrated tools like shipment calendars and delivery confirmations help keep your sales process smooth and efficient.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Flexible Product and Pricing Configuration: </span>
          {" "}Easily configure products, manage pricing, and streamline your store operations, allowing you to respond quickly to customer demands and market changes, all within the CRM FARM platform.
        </>,
      ],
    },
  },
  {
    id: "accounts",
    title: "Accounts",
    image: AccountImg,
    content: {
      subContent: "Accounting is the language of business.",
      author: "Warren Buffett",
      para: "",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Integrated Feedback System: </span>
          {" "} The Accounts module features a built-in feedback template, allowing you to easily collect, track, and analyse customer feedback, helping you continuously improve your services.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Detailed Feedback Reports: </span>
          {" "} Gain valuable insights with comprehensive feedback reports that highlight customer satisfaction trends, enabling data-driven decisions to enhance your sales strategy.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Seamless Customer Interaction: </span>
          {" "} The module’s conversation tools facilitate smooth communication with customers, ensuring that their concerns and inquiries are promptly addressed, fostering stronger relationships.
        </>,
      ],
    },
  },
  {
    id: "feedbackreports",
    title: "Feedback Reports",
    image: FeedbackImg,
    content: {
      subContent: "The best way to predict the future is to create it, and feedback is a crucial part of the creation process.",
      author: "Peter Drucker",
      para: "Unlock Valuable Insights with CRM FARM’s Feedback Summary",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Overview: </span>
          {" "} CRM FARM’s Feedback Summary provides a detailed and concise overview of customer feedback, helping you quickly understand overall sentiment and identify key trends.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Data-Driven Decisions: </span>
          {" "} With actionable insights from feedback reports, you can make informed decisions to enhance customer satisfaction and improve service quality.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Enhanced Accountability:  </span>
          {" "} Track and evaluate feedback metrics to ensure accountability within your team, driving continuous improvement and fostering a customer-centric approach.
        </>,
      ],
    },
  },
  {
    id: "configureproduct",
    title: "Configure Product",
    image: ConfigProductImg,
    content: {
      subContent: "A product is not just a product; it’s a promise to the customer.",
      author: "Steve Jobs",
      para: "",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Product Management:</span>
          {" "} CRM FARM's product configuration allows you to efficiently manage product categories, groups, and individual items, providing a structured and organized approach to handling your product inventory.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Customizable Discount Settings: </span>
          {" "} Easily set up and manage discounts tailored to specific product groups or individual items, enabling flexible pricing strategies that enhance your sales potential and respond to market demands.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Integrated Supplier Management: </span>
          {" "} The module includes robust supplier management features, allowing seamless coordination with suppliers and ensuring you maintain optimal stock levels and product availability.
        </>,
      ],
    },
  },
  {
    id: "store",
    title: "Store",
    image: StoreImg,
    content: {
      subContent: "Good product management is about understanding the customer’s needs and delivering the right solution.",
      author: "Melissa Perri",
      para: "Streamline Your Inventory with CRM FARM’s Store Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Efficient Store Creation:</span>
          {" "} Easily set up and manage multiple stores with CRM FARM’s intuitive store creation feature, simplifying the process of expanding your business and improving your operational efficiency.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Seamless Product Inward Management: </span>
          {" "} The product inward feature allows for smooth tracking and management of inventory as new products are added, ensuring accurate stock levels and reducing errors.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Enhanced Inventory Control:</span>
          {" "} With integrated store and product inward functionalities, CRM FARM provides comprehensive control over your inventory, facilitating better stock management and supporting informed business decisions.
        </>,
      ],
    },
  },
  {
    id: "territorymanagement",
    title: "Territory Management",
    image: TerritoryImg,
    content: {
      subContent: "Effective territory management is crucial for aligning your sales efforts with market opportunities and driving business growth.",
      author: "Jill Konrath",
      para: "Optimize Sales Performance with CRM FARM’s Territory Management",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Strategic Territory Hierarchy:</span>
          {" "} CRM FARM’s robust territory hierarchy feature allows for the effective organization and segmentation of sales territories, enabling targeted and strategic management that drives performance and maximizes sales potential.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Dynamic Territory and Target Management: </span>
          {" "} Easily manage and adjust territories and sales targets to align with evolving business goals and market conditions, ensuring that your sales strategy remains agile and responsive.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Enhanced Sales Performance Tracking:</span>
          {" "} With integrated sales and salesperson targets, CRM FARM provides comprehensive tracking and insights into sales performance, helping to drive accountability and achieve your sales objectives.
        </>,
      ],
    },
  },
  {
    id: "projectmanagement",
    title: "Project Management",
    image: ProjectImg,
    content: {
      subContent: "Operations keep the lights on, strategy provides a light at the end of the tunnel, but project management is the train engine that moves the organization forward.",
      author: "Joy Gumz",
      para: "Streamline Your Project Success with CRM FARM's Comprehensive Project Management Tools",
      points: [
        "CRMFARM offers a robust project management module that integrates task and milestone tracking directly with your CRM workflows.",
        "Ensures that all project-related activities are aligned with your customer management processes, providing a unified approach to managing both customer relationships and project deliverables.",
        "Real-Time Collaboration Tools, With CRMFARM, project teams can collaborate seamlessly using built-in communication tools, file sharing, and real-time updates."
      ],
    },
  },
  {
    id: "taskmanagement",
    title: "Task Management",
    image: TaskManagementImg,
    content: {
      subContent: "Effective task management is the key to ensuring that every project is completed on time and to the highest standard.",
      author: "David Allen",
      para: "Master Task Efficiency with CRM FARM’s Advanced Task Management",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Organized Task Tracking:</span>
          {" "} With the task list feature, easily organize, prioritize, and track tasks, ensuring every detail is managed effectively and deadlines are met.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Visualize Progress with Gantt Charts: </span>
          {" "} The Gantt chart feature provides a clear visual representation of task timelines and dependencies, helping you manage projects more efficiently and stay on schedule.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Integrated Task Calendar:</span>
          {" "} The task calendar offers a centralized view of all tasks and deadlines, facilitating better planning and coordination while keeping your team aligned and informed.
        </>,
      ],
    },
  },
  {
    id: "workflow",
    title: "Work Flow",
    image: WorkflowImg,
    content: {
      subContent: "A well-designed workflow in CRM streamlines processes, reduces manual tasks, and enhances productivity by automating routine tasks.",
      author: "David Raab",
      para: "Transform Your Operations with CRM FARM’s Customizable Workflow Management",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Tailored Workflow Automation: </span>
          {" "} CRM FARM’s Work rules feature allows users to create and customize workflows with specific triggers, automating routine tasks and ensuring consistent execution of business processes.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Enhanced Efficiency with Triggers: </span>
          {" "} Set up workflow triggers to automatically initiate actions based on predefined conditions, streamlining operations and reducing manual intervention for greater efficiency.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Flexible and Adaptable Workflows:</span>
          {" "} Customize workflows to fit your unique business needs, providing the flexibility to adapt to changing requirements and optimize processes for improved productivity and performance.
        </>,
      ],
    },
  },
  {
    id: "reports",
    title: "Reports",
    image: ReportImg,
    content: {
      subContent: "Reports in CRM are not just about data; they are about revealing insights that drive smarter business decisions and strategic growth.",
      author: "Bernard Marr",
      para: "Unlock Insights with CRM FARM’s Comprehensive Reporting Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Holistic Performance Analysis: </span>
          {" "} CRM FARM’s reports module provides a complete view of sales performance, lead and opportunity management, and customer management, empowering you to make informed decisions based on detailed analytics.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Evaluate Marketing and Retention: </span>
          {" "} Measure marketing effectiveness and track customer retention with tailored reports, allowing you to assess the impact of your strategies and refine your approach for better results.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Dynamic Reporting Capabilities: </span>
          {" "} With options for new and sales funnel reports, CRM FARM offers flexibility in generating insights and tracking progress through various stages of the sales cycle, enhancing your strategic planning and execution.
        </>,
      ],
    },
  },
  {
    id: "salesperformance",
    title: "Sales Performance",
    image: SalesPerformanceImg,
    content: {
      subContent: "Sales performance in CRM is driven by data-driven insights that enable teams to focus their efforts on the most promising opportunities.",
      author: "Brian Tracy",
      para: "Maximize Sales Efficiency with CRM FARM’s Sales Performance Reporting",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Insightful Sales Pipeline Analysis: </span>
          {" "} The sales pipeline report in CRM FARM provides a clear view of potential revenue and sales opportunities, helping you prioritize efforts and close deals more effectively.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Detailed Product/Service Performance Tracking: </span>
          {" "} Gain a deep understanding of how each product or service is performing with specific reports, enabling data-driven decisions to optimize your offerings and boost profitability.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Sales Activity Monitoring: </span>
          {" "} Track and analyse sales activities in real-time, ensuring your team’s efforts are aligned with goals and contributing to overall success.
        </>,
      ],
    },
  },
  {
    id: "leadandopportunitymanagement",
    title: "Lead and opportunity Management",
    image: LeadOpportunityImg,
    content: {
      subContent: "CRM systems are essential for managing leads and opportunities, providing the tools to prioritize prospects and maximize conversion rates.",
      author: "David C. Edelman",
      para: "Optimize Lead Conversion with CRM FARM’s Lead and Opportunity Management Reports",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Conversion Insights: </span>
          {" "} The Lead conversion and conversion rate reports in CRM FARM provide detailed analytics on how effectively leads are turning into customers, enabling you to refine your strategies and boost conversion rates.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Predictive Sales Forecasting: </span>
          {" "} The sales forecast by stage report offers valuable predictions based on current pipeline stages, allowing for more accurate planning and resource allocation to meet sales targets.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>In-Depth Opportunity Analysis: </span>
          {" "} With reports like opportunity aging and lead source, CRM FARM gives you a clear understanding of where your leads originate and how long opportunities are taking to close, helping you optimize your sales funnel and reduce sales cycle times.
        </>,
      ],
    },
  },
  {
    id: "customerengagementreports",
    title: "Customer Engagement",
    image: CustomerEngageImg,
    content: {
      subContent: "Customer engagement in CRM is about creating meaningful interactions that build trust and foster long-term loyalty.",
      author: "Don Peppers",
      para: "Enhance Connections with CRM FARM’s Customer Engagement Reports",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Email Campaign Performance Insights: </span>
          {" "} CRM FARM’s email campaign performance report offers in-depth analysis of your email marketing efforts, providing actionable data to improve open rates, click-throughs, and overall campaign success.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Social Media Engagement Tracking: </span>
          {" "} The social media engagement report helps you monitor and evaluate your brand’s impact across social platforms, allowing you to refine your strategies and increase audience interaction.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Engagement Analytics: </span>
          {" "} With both email and social media reports, CRM FARM delivers a holistic view of your customer engagement efforts, enabling you to create more effective, data-driven marketing campaigns.
        </>,
      ],
    },
  },
  {
    id: "marketingeffectiveness",
    title: "Marketing Effectiveness",
    image: MarkettingEffectiveImg,
    content: {
      subContent: "Marketing effectiveness in CRM is not just about reaching customers; it’s about engaging them with the right message at the right time.",
      author: "Seth Godin",
      para: "Drive Results with CRM FARM’s Marketing Effectiveness Reports",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Lead Generation Insights: </span>
          {" "} CRM FARM’s lead generation report provides detailed analysis of your marketing efforts, helping you identify the most effective channels and strategies for attracting new leads.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Conversion Rate Optimization: </span>
          {" "} The conversion rate report offers crucial data on how well your marketing campaigns are turning leads into customers, enabling you to fine-tune your approach for maximum ROI.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Holistic Marketing Performance Evaluation: </span>
          {" "} By combining lead generation and conversion metrics, CRM FARM gives you a comprehensive view of your marketing effectiveness, empowering you to make informed decisions that enhance campaign success.
        </>,
      ],
    },
  },
  {
    id: "customerretention",
    title: "Customer Retention",
    image: CustomerRetentionImg,
    content: {
      subContent: "Customer retention is not just about keeping customers; it's about creating experiences that make them want to stay.",
      author: "Shep Hyken",
      para: "Boost Loyalty with CRM FARM’s Customer Retention Insights",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Proactive Customer Churn Analysis: </span>
          {" "} CRM FARM’s customer churn report provides critical insights into why customers are leaving, enabling you to take proactive measures to reduce churn and improve retention rates.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Identify At-Risk Customers: </span>
          {" "} With detailed churn data, you can identify patterns and trends, allowing you to target at-risk customers with tailored retention strategies before they decide to leave.
        </>,
      ],
    },
  },
  {
    id: "generalreports",
    title: "General Reports",
    image: GeneralReportImg,
    content: {
      subContent: "Effective CRM reporting transforms raw data into actionable insights, enabling businesses to understand their performance and make informed decisions.",
      author: "Paul Greenberg",
      para: "Stay Ahead with CRM FARM’s Comprehensive New Reports",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Detailed Sales Tracking: </span>
          {" "} With daily, weekly, and monthly sales reports, CRM FARM offers granular insights into your sales performance, enabling you to monitor progress, identify trends, and adjust strategies in real-time.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Holistic Customer Analysis: </span>
          {" "} From customer churn and retention to customer addition reports, CRM FARM provides a full spectrum of customer analytics, helping you understand and improve customer lifecycle management.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Performance and Opportunity Insights: </span>
          {" "} The sales rep performance and lead & opportunity reports empower you to evaluate your team’s effectiveness and optimize sales strategies, ensuring that every opportunity is maximized for success.
        </>,
      ],
    },
  },
  {
    id: "salesfunnel",
    title: "Sales Funnel Reports",
    image: SalesFunnelImg,
    content: {
      subContent: "Understanding your sales funnel is crucial for forecasting and managing sales effectively; it helps you visualize where leads are in the process and how to move them forward.",
      author: "Neil Rackham",
      para: "Optimize Your Sales Journey with CRM FARM’s Sales Funnel Reports",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Sales Funnel Analysis:</span>
          {" "}CRM FARM’s Sales funnel report provides a clear view of your entire sales process, helping you identify bottlenecks and opportunities for improvement at every stage.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Pipeline Development Insights: </span>
          The pipeline development report offers detailed tracking of how leads progress through your sales pipeline, allowing you to fine-tune strategies for faster and more efficient conversions.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Stage Distribution Clarity: </span>
          With the stage distribution CRM report, gain insights into where your prospects are within the sales funnel, enabling more accurate forecasting and targeted efforts to move deals forward.
        </>
      ],
    },
  },
  {
    id: "analytics",
    title: "Analytics",
    image: AnalyticsImg,
    content: {
      subContent: "In CRM, analytics is not just about reporting numbers; it's about understanding trends and making data-driven decisions that propel your business forward.",
      author: "Paul Greenberg",
      para: "Unlock Actionable Insights with CRM FARM’s Advanced Analytics Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Targeted Sales Analysis: </span>
          {" "} With features like sales by customer and sales opportunities overview, CRM FARM’s Analytics module helps you pinpoint high-value customers and identify key opportunities, driving more effective sales strategies.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Comprehensive Activity Tracking: </span>
          The activity overview and call activities reports provide a clear picture of your team’s performance, enabling better resource allocation and improved productivity.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Goal-Oriented Performance Monitoring: </span>
          Track progress against your sales goals with the sales goals feature and KPI dashboard, ensuring that your team stays on target and aligned with business objectives.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>In-Depth Call Analytics: </span>
          Analyse call duration and call length vs. time of day to optimize communication strategies, ensuring that your sales team connects with customers at the most effective times.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Holistic Sales Performance Visualization: </span>
          The sales dashboard and monthly retail sales reports offer a comprehensive view of your sales performance, enabling data-driven decision-making and continuous improvement.
        </>,
      ],
    },
  },
  {
    id: "custom-form",
    title: "Custom Form",
    image: CustomFormImg,
    content: {
      subContent: "Custom forms in CRM allow businesses to capture the right data, ensuring that every interaction is personalized and relevant.",
      author: "Tony Zambito",
      para: "Empower Your Data Collection with CRM FARM’s Custom Form Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Tailored Form Creation: </span>
          CRM FARM’s Customize Form module allows users to design and create forms that fit their specific needs, whether it’s for capturing customer, vendor, or employee details, ensuring maximum flexibility.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Enhanced Data Collection: </span>
          Easily gather the exact information you need with customizable fields, enabling more accurate and relevant data collection that aligns with your business objectives.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>User-Friendly Interface: </span>
          The intuitive design of the form builder makes it simple for users to create and deploy forms quickly, without the need for technical expertise.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Versatile Application: </span>
          From customer intake to employee on-boarding, the Customize Form module can be adapted to a wide range of use cases, making it a versatile tool for any department or business process.
        </>,
      ],
    },
  },
  // user admin
  {
    id: "configuration",
    title: "Configuration",
    image: ConfigProductImg,
    content: {
      subContent: "A well-configured CRM doesn't just store data; it drives processes and decisions that align with your business goals.",
      author: "Susan Cramm",
      para: "Key Features of the Configuration Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Customizable Transaction Series:  </span>
          Allows users to define and manage transaction series to maintain consistency and control over numbering sequences for various transactions.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>System Preferences Management:  </span>
          Provides flexibility to configure system settings, enabling the adaptation of the CRM system to match organizational needs and preferences.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Look-Up Lists Management:  </span>
          Facilitates the creation and maintenance of look-up lists for various fields, ensuring data consistency and ease of use across the CRM system.
        </>,
      ],
    },
  },
  {
    id: "task-management",
    title: "Task Management",
    image: TaskManagementImg,
    content: {
      subContent: "The power of CRM lies in its ability to organize tasks, turning complexity into clarity.",
      author: "James Clear",
      para: "Key Features of Task Management Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Task Listing for Admin: </span>
          Admins can easily access and manage all self-created tasks, ensuring clear oversight of ongoing and pending activities.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Calendar View Integration: </span>
          Visualize tasks in a calendar format, allowing for effective time management and planning by viewing tasks by day, week, or month.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Streamlined Task Management: </span>
          Facilitates the efficient tracking and organization of tasks, enhancing productivity and ensuring that no tasks are overlooked.
        </>,
      ],
    },
  },
  {
    id: "request",
    title: "Request",
    image: CampaignImg,
    content: {
      subContent: "Managing storage, licensing, and add-ons within a CRM is not just about organization; it's about creating a seamless experience that supports growth and flexibility.",
      author: "Gartner Research",
      para: "Key Features of Request Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Seamless License Management: </span>
          Easily request and manage additional licenses, ensuring that your team always has the access they need as your business grows.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Flexible Storage and Add-On Purchases: </span>
          Quickly scale your CRM capabilities by requesting additional storage or purchasing add-ons, allowing for tailored system enhancements.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Efficient Plan Management: </span>
          Simplify the process of plan cancellations and renewals, providing full control over your CRM subscriptions and services to meet changing business needs.
        </>,
      ],
    },
  },
  {
    id: "Membership",
    title: "Membership",
    image: CampaignImg,
    content: {
      subContent:
        "Managing storage, licensing, and add-ons within a CRM is not just about organization; it's about creating a seamless experience that supports growth and flexibility.",
      author: "Gartner Research",
      para: "Key Features of Membership Module",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>
            Seamless License Management:{" "}
          </span>
          Easily request and manage additional licenses, ensuring that your team
          always has the access they need as your business grows.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>
            Flexible Storage and Add-On Purchases:{" "}
          </span>
          Quickly scale your CRM capabilities by requesting additional storage
          or purchasing add-ons, allowing for tailored system enhancements.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>
            Efficient Plan Management:{" "}
          </span>
          Simplify the process of plan cancellations and renewals, providing
          full control over your CRM subscriptions and services to meet changing
          business needs.
        </>,
      ],
    },
  },
  {
    id: "settings",
    title: "Settings",
    image: WorkflowImg,
    content: {
      subContent: "The effectiveness of a CRM system is directly proportional to the precision of its settings. Tailor your settings, and your CRM will tailor its performance to your needs.",
      author: "Paul Greenberg",
      para: "Comprehensive Settings Module Features",
      points: [
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Customizable Company and User Roles: </span>
          Easily edit company details and define user roles, ensuring that the CRM system aligns with your organizational structure and access needs.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Advanced Lead and Deal Management:  </span>
          Set up lead scoring, lead prediction, deal weights, and deal factors, enabling data-driven decision-making for better sales outcomes.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Automated Response and Template Management:  </span>
          Manage auto-responder lists and templates to streamline communication processes, enhancing customer interaction efficiency.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Flexible Financial Configurations:   </span>
          Set up tax details, factory settings, and invoice templates to ensure that financial operations are accurately represented and compliant with regulations.
        </>,
        <>
          <span style={{ fontWeight: 600, fontSize: "15px" }}>Sales Performance Targeting:  </span>
          Define and monitor salesperson targets and add agents, empowering the sales team to achieve their goals with clear expectations and accountability.
        </>,
      ],
    },
  },
];
