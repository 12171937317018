import React from "react";
import { SiMicrosoftoutlook } from "react-icons/si";
import { GrSystem, GrTransaction } from "react-icons/gr";
import { AiOutlineFileSearch } from "react-icons/ai";

const lookupmenu = [
  {
    id: 6,
    title: "Configuration",
    path: "configuration",
    type: "collapsible",
    icon: <SiMicrosoftoutlook style={{ fontSize: 18 }} />,
    color: "#4B4B4B",
    children: [
      {
        id: 13,
        path: `settings/preferences/transaction-series`,
        title: "Transaction Series",
        type: "nav-item",
        icon: <GrTransaction style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 14,
        path: `settings/preferences/system`,
        title: "System Preferences",
        type: "nav-item",
        icon: <GrSystem style={{ fontSize: 14, }} />,
        color: "#4B4B4B",
      },
      {
        id: 15,
        path: `lookup/list-lookup`,
        title: "Lookup Lists",
        type: "nav-item",
        icon: <AiOutlineFileSearch style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 15,
        path: `configuration/convertion-bot`,
        title: "Convertion Bot Configuration",
        type: "nav-item",
        icon: <AiOutlineFileSearch style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
      {
        id: 15,
        path: `configuration/ML-Lead`,
        title: "ML Model Configuration",
        type: "nav-item",
        icon: <AiOutlineFileSearch style={{ fontSize: 16, }} />,
        color: "#4B4B4B",
      },
    ],
  },
];
export default lookupmenu;